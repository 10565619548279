import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { formatCurrency, roundUp } from "../utility/utility";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend);

const ClientCard = ({ chartRef1, chartRef2 }) => {
  const { paymentData, contextData } = usePaymentContext();

  const {
    s_Payrate1,
    s_StatBillingRate1,
    c_Payrate1,
    c_StatBillingRate1,
    p_Payrate1,
    p_StatBillingRate1,
    e1_Payrate1,
    e1_StatBillingRate1,
    e2_Payrate1,
    e2_StatBillingRate1,
    total1,
  } = contextData;

  // c_BillingRate1;

  const {
    supervisorTitle,
    supervisorProfit,
    supervisorHours,
    supervisorStatHours,

    conciergeTitle,
    conciergeProfit,
    conciergeHours,
    conciergeStatHours,

    patrolTitle,
    patrolProfit,
    patrolHours,
    patrolStatHours,

    extraTitle1,
    extraProfit1,
    extraHours1,
    extraStatHours1,

    extraTitle2,
    extraProfit2,
    extraHours2,
    extraStatHours2,

    overheadAmt,
    cppPercent,
    eiPercent,
    wsibPercent,
    healthTax,
    vacationPay,
  } = paymentData;

  const supervisorHours_b = supervisorHours * 2;
  const conciergeHours_b = conciergeHours * 2;
  const patrolHours_b = patrolHours * 2;
  const extraHours1_b = extraHours1 * 2;
  const extraHours2_b = extraHours2 * 2;

  const s_empincome = s_Payrate1 * supervisorHours_b;
  const s_cpp = (s_empincome * cppPercent) / 100;
  const s_ei = (s_empincome * eiPercent) / 100;
  const s_wsib = (s_empincome * wsibPercent) / 100;
  const s_healthtax = (s_empincome * healthTax) / 100;
  const s_vacationpay = (s_empincome * vacationPay) / 100;
  const s_stathours =
    (((supervisorStatHours * s_StatBillingRate1 * 9) / 26) *
      (100 - supervisorProfit)) /
    100;
  const s_overhead = supervisorHours_b * overheadAmt;
  const s_totalincome =
    s_empincome +
    s_cpp +
    s_ei +
    s_wsib +
    s_healthtax +
    s_vacationpay +
    s_stathours +
    s_overhead;

  const c_empincome = c_Payrate1 * conciergeHours_b;
  const c_cpp = (c_empincome * cppPercent) / 100;
  const c_ei = (c_empincome * eiPercent) / 100;
  const c_wsib = (c_empincome * wsibPercent) / 100;
  const c_healthtax = (c_empincome * healthTax) / 100;
  const c_vacationpay = (c_empincome * vacationPay) / 100;

  const c_stathours =
    (((conciergeStatHours * c_StatBillingRate1 * 9) / 26) *
      (100 - conciergeProfit)) /
    100;
  const c_overhead = conciergeHours_b * overheadAmt;
  const c_totalincome =
    c_empincome +
    c_cpp +
    c_ei +
    c_wsib +
    c_healthtax +
    c_vacationpay +
    c_stathours +
    c_overhead;

  const p_empincome = p_Payrate1 * patrolHours_b;
  const p_cpp = (p_empincome * cppPercent) / 100;
  const p_ei = (p_empincome * eiPercent) / 100;
  const p_wsib = (p_empincome * wsibPercent) / 100;
  const p_healthtax = (p_empincome * healthTax) / 100;
  const p_vacationpay = (p_empincome * vacationPay) / 100;
  const p_stathours =
    (((patrolStatHours * p_StatBillingRate1 * 9) / 26) * (100 - patrolProfit)) /
    100;
  const p_overhead = patrolHours_b * overheadAmt;
  const p_totalincome =
    p_empincome +
    p_cpp +
    p_ei +
    p_wsib +
    p_healthtax +
    p_vacationpay +
    p_stathours +
    p_overhead;

  const e1_empincome = e1_Payrate1 * extraHours1_b;
  const e1_cpp = (e1_empincome * cppPercent) / 100;
  const e1_ei = (e1_empincome * eiPercent) / 100;
  const e1_wsib = (e1_empincome * wsibPercent) / 100;
  const e1_healthtax = (e1_empincome * healthTax) / 100;
  const e1_vacationpay = (e1_empincome * vacationPay) / 100;
  const e1_stathours =
    (((extraStatHours1 * e1_StatBillingRate1 * 9) / 26) *
      (100 - extraProfit1)) /
    100;
  const e1_overhead = extraHours1_b * overheadAmt;
  const e1_totalincome =
    e1_empincome +
    e1_cpp +
    e1_ei +
    e1_wsib +
    e1_healthtax +
    e1_vacationpay +
    e1_stathours +
    e1_overhead;

  const e2_empincome = e2_Payrate1 * extraHours2_b;
  const e2_cpp = (e2_empincome * cppPercent) / 100;
  const e2_ei = (e2_empincome * eiPercent) / 100;
  const e2_wsib = (e2_empincome * wsibPercent) / 100;
  const e2_healthtax = (e2_empincome * healthTax) / 100;
  const e2_vacationpay = (e2_empincome * vacationPay) / 100;
  const e2_stathours =
    (((extraStatHours2 * e2_StatBillingRate1 * 9) / 26) *
      (100 - extraProfit2)) /
    100;
  const e2_overhead = extraHours2_b * overheadAmt;
  const e2_totalincome =
    e2_empincome +
    e2_cpp +
    e2_ei +
    e2_wsib +
    e2_healthtax +
    e2_vacationpay +
    e2_stathours +
    e2_overhead;

  const totalHours_b =
    roundUp(supervisorHours_b) +
    roundUp(conciergeHours_b) +
    roundUp(patrolHours_b) +
    roundUp(extraHours1_b) +
    roundUp(extraHours2_b);
  const total_empincome =
    roundUp(s_empincome) +
    roundUp(c_empincome) +
    roundUp(p_empincome) +
    roundUp(e1_empincome) +
    roundUp(e2_empincome);
  const total_cpp =
    roundUp(s_cpp) +
    roundUp(c_cpp) +
    roundUp(p_cpp) +
    roundUp(e1_cpp) +
    roundUp(e2_cpp);
  const total_ei =
    roundUp(s_ei) +
    roundUp(c_ei) +
    roundUp(p_ei) +
    roundUp(e1_ei) +
    roundUp(e2_ei);
  const total_wsib =
    roundUp(s_wsib) +
    roundUp(c_wsib) +
    roundUp(p_wsib) +
    roundUp(e1_wsib) +
    roundUp(e2_wsib);
  const total_healthtax =
    roundUp(s_healthtax) +
    roundUp(c_healthtax) +
    roundUp(p_healthtax) +
    roundUp(e1_healthtax) +
    roundUp(e2_healthtax);
  const total_vacationpay =
    roundUp(s_vacationpay) +
    roundUp(c_vacationpay) +
    roundUp(p_vacationpay) +
    roundUp(e1_vacationpay) +
    roundUp(e2_vacationpay);
  const total_stathours =
    roundUp(s_stathours) +
    roundUp(c_stathours) +
    roundUp(p_stathours) +
    roundUp(e1_stathours) +
    roundUp(e2_stathours);
  const total_overhead =
    roundUp(s_overhead) +
    roundUp(c_overhead) +
    roundUp(p_overhead) +
    roundUp(e1_overhead) +
    roundUp(e2_overhead);
  const total_income =
    total_empincome +
    total_cpp +
    total_ei +
    total_wsib +
    total_healthtax +
    total_vacationpay +
    total_stathours +
    total_overhead;

  const total1_b = roundUp(total1 / 26);
  const profit_margin = roundUp(total1_b - total_income);
  const total_chart_data1 = total1_b + total_income + profit_margin;

  const per_total1_b = roundUp((total1_b * 100) / total_chart_data1);
  const per_total_income = roundUp((total_income * 100) / total_chart_data1);
  const per_profit_margin = roundUp((profit_margin * 100) / total_chart_data1);

  const chart_data1 = {
    labels: ["Total Billing", "Total Payroll", "Profit Margin"],
    datasets: [
      {
        // label: "Department Expenses",
        data: [per_total1_b, per_total_income, per_profit_margin],
        backgroundColor: ["#9b59b6", "#3498db", "#2ecc71"],
        hoverBackgroundColor: ["#9b59b6cc", "#3498dbcc", "#2ecc71cc"],
      },
    ],
  };

  const total_chart_data2 =
    total_stathours +
    total_cpp +
    total_ei +
    total_wsib +
    total_healthtax +
    total_vacationpay +
    total_overhead +
    total_empincome;

  const per_total_stathours = roundUp(
    (total_stathours * 100) / total_chart_data2
  );
  const per_total_cpp = roundUp((total_cpp * 100) / total_chart_data2);
  const per_total_ei = roundUp((total_ei * 100) / total_chart_data2);
  const per_total_wsib = roundUp((total_wsib * 100) / total_chart_data2);
  const per_total_healthtax = roundUp(
    (total_healthtax * 100) / total_chart_data2
  );
  const per_total_vacationpay = roundUp(
    (total_vacationpay * 100) / total_chart_data2
  );
  const per_total_overhead = roundUp(
    (total_overhead * 100) / total_chart_data2
  );
  const per_total_empincome = roundUp(
    (total_empincome * 100) / total_chart_data2
  );

  const chart_data2 = {
    labels: [
      "Employee Wages",
      "Statutory Holiday",
      "CPP",
      "EI",
      "WSIB",
      "Health Tax",
      "Vacation Pay",
      "Overhead",
    ],
    datasets: [
      {
        // label: "Employee Expenses",
        data: [
          per_total_empincome,
          per_total_stathours,
          per_total_cpp,
          per_total_ei,
          per_total_wsib,
          per_total_healthtax,
          per_total_vacationpay,
          per_total_overhead,
        ],
        backgroundColor: [
          "#9f4405",
          "#5b9bd8",
          "#f17b2d",
          "#a7a7a7",
          "#fabf3b",
          "#4e78c4",
          "#75b14f",
          "#1e5b92",
        ],
        hoverBackgroundColor: [
          "#9f4405cc",
          "#5b9bd8cc",
          "#f17b2dcc",
          "#a7a7a7cc",
          "#fabf3bcc",
          "#4e78c4cc",
          "#75b14fcc",
          "#1e5b92cc",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },
    },
  };

  return (
    <div className="container mx-auto bg-white p-4">
      <div className="overflow-x-auto">
        <div className="m-2 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
        Business Operation Performance Chart
        </div>
        <div className="grid grid-cols-1 gap-y-4 overflow-x-auto">
          <table className="min-w-full bg-white table-fixed">
            <tbody>
              <tr className="bg-blue-50">
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Building
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Position
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Current Pay Rate
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Bi-Weekly Hours
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Total Bi-Weekly Billing Before Tax
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Employee Gross Income
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  CPP
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  EI
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  WSIB
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Health Tax
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Vacation Pay
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Statutory Holiday x 9
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Overhead
                </th>
                <th className="py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase w-14">
                  Total Bi-Weekly Company Expenses
                </th>
              </tr>
              <tr>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {supervisorTitle} @ 
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {s_Payrate1 !== 0 && formatCurrency(s_Payrate1)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {supervisorHours_b !== 0 && supervisorHours_b}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_empincome !== 0 && formatCurrency(s_empincome)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_cpp !== 0 && formatCurrency(roundUp(s_cpp, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_ei !== 0 && formatCurrency(roundUp(s_ei, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_wsib !== 0 && formatCurrency(roundUp(s_wsib, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_healthtax !== 0 && formatCurrency(roundUp(s_healthtax, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_vacationpay !== 0 &&
                    formatCurrency(roundUp(s_vacationpay, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_stathours !== 0 && formatCurrency(roundUp(s_stathours, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_overhead !== 0 && formatCurrency(roundUp(s_overhead, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {s_totalincome !== 0 &&
                    formatCurrency(roundUp(s_totalincome, 2))}
                </td>
              </tr>
              <tr>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {conciergeTitle} @ 
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {c_Payrate1 !== 0 && formatCurrency(c_Payrate1)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {conciergeHours_b !== 0 && conciergeHours_b}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_empincome !== 0 && formatCurrency(c_empincome)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_cpp !== 0 && formatCurrency(roundUp(c_cpp, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_ei !== 0 && formatCurrency(roundUp(c_ei, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_wsib !== 0 && formatCurrency(roundUp(c_wsib, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_healthtax !== 0 && formatCurrency(roundUp(c_healthtax, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_vacationpay !== 0 &&
                    formatCurrency(roundUp(c_vacationpay, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_stathours !== 0 && formatCurrency(roundUp(c_stathours, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_overhead !== 0 && formatCurrency(roundUp(c_overhead, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {c_totalincome !== 0 &&
                    formatCurrency(roundUp(c_totalincome, 2))}
                </td>
              </tr>
              {patrolProfit && (
              <>
              <tr>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {patrolTitle} @ 
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {p_Payrate1 !== 0 && formatCurrency(p_Payrate1)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {patrolHours_b !== 0 && patrolHours_b}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_empincome !== 0 && formatCurrency(p_empincome)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_cpp !== 0 && formatCurrency(roundUp(p_cpp, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_ei !== 0 && formatCurrency(roundUp(p_ei, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_wsib !== 0 && formatCurrency(roundUp(p_wsib, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_healthtax !== 0 && formatCurrency(roundUp(p_healthtax, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_vacationpay !== 0 &&
                    formatCurrency(roundUp(p_vacationpay, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_stathours !== 0 && formatCurrency(roundUp(p_stathours, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_overhead !== 0 && formatCurrency(roundUp(p_overhead, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {p_totalincome !== 0 &&
                    formatCurrency(roundUp(p_totalincome, 2))}
                </td>
              </tr>
              </>
              )}
              {extraProfit1 && (
              <>
              <tr>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {extraTitle1} @ 
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {e1_Payrate1 !== 0 && formatCurrency(e1_Payrate1)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {extraHours1_b !== 0 && extraHours1_b}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_empincome !== 0 && formatCurrency(e1_empincome)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_cpp !== 0 && formatCurrency(roundUp(e1_cpp, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_ei !== 0 && formatCurrency(roundUp(e1_ei, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_wsib !== 0 && formatCurrency(roundUp(e1_wsib, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_healthtax !== 0 &&
                    formatCurrency(roundUp(e1_healthtax, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_vacationpay !== 0 &&
                    formatCurrency(roundUp(e1_vacationpay, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_stathours !== 0 &&
                    formatCurrency(roundUp(e1_stathours, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_overhead !== 0 && formatCurrency(roundUp(e1_overhead, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e1_totalincome !== 0 &&
                    formatCurrency(roundUp(e1_totalincome, 2))}
                </td>
              </tr>
              </>
              )}
              {extraProfit2 && (
              <>
              <tr>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {extraTitle2} @ 
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {e2_Payrate1 !== 0 && formatCurrency(e2_Payrate1)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {extraHours2_b !== 0 && extraHours2_b}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_empincome !== 0 && formatCurrency(e2_empincome)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_cpp !== 0 && formatCurrency(roundUp(e2_cpp, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_ei !== 0 && formatCurrency(roundUp(e2_ei, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_wsib !== 0 && formatCurrency(roundUp(e2_wsib, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_healthtax !== 0 &&
                    formatCurrency(roundUp(e2_healthtax, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_vacationpay !== 0 &&
                    formatCurrency(roundUp(e2_vacationpay, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_stathours !== 0 &&
                    formatCurrency(roundUp(e2_stathours, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_overhead !== 0 && formatCurrency(roundUp(e2_overhead, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {e2_totalincome !== 0 &&
                    formatCurrency(roundUp(e2_totalincome, 2))}
                </td>
              </tr>
              </>
              )}
              <tr className="bg-blue-50">
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center"></td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                  {totalHours_b}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(total1_b)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(total_empincome)}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_cpp, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_ei, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_wsib, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_healthtax, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_vacationpay, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_stathours, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_overhead, 2))}
                </td>
                <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                  {formatCurrency(roundUp(total_income, 2))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* 2 Tables in Row */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 mt-10">
          <div>
            <div className="m-2 tracking-wide text-sm text-indigo-500 font-semibold">
              INCOME VERSUS EXPENSES
            </div>
            {/* <div className="flex flex-col sm:flex-row">
            </div> */}
            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:w-[40%] mb-4 sm:mb-0">
                <table className="min-w-full bg-white table-fixed">
                  <tbody>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Total Billing
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total1_b)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Total Payroll
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(roundUp(total_income, 2))}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Profit Margin
                      </td>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(roundUp(profit_margin, 2))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full sm:w-[60%] flex justify-center items-center">
                <div className="w-full h-full max-h-[300px] sm:max-h-none p-5">
                  <Pie ref={chartRef1} data={chart_data1} options={options} />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="m-2 tracking-wide text-sm text-indigo-500 font-semibold">
              EMPLOYEE WAGES and COMPANY EXPENSES and CONTRIBUTION
            </div>
            <div className="flex flex-col sm:flex-row">
              <div className="w-full sm:w-[40%] mb-4 sm:mb-0">
                <table className="min-w-full bg-white table-fixed">
                  <tbody>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Statutory Holiday
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_stathours)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        CPP
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_cpp)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        EI
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_ei)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        WSIB
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_wsib)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Health Tax
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_healthtax)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Vacation Pay
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_vacationpay)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Overhead
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_overhead)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left">
                        Employee Wages
                      </td>
                      <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_empincome)}
                      </td>
                    </tr>
                    <tr>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-left"></td>
                      <td className="bg-blue-50 py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                        {formatCurrency(total_income)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full sm:w-[60%] flex justify-center items-center">
                <div className="w-full h-full max-h-[300px] sm:max-h-none p-5">
                  <Pie ref={chartRef2} data={chart_data2} options={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientCard;
