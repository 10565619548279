import React, { createContext, useContext, useState, useCallback } from "react";
import { roundDown, roundUp } from "../utility/utility";

// Create the context
const PaymentContext = createContext();

// Create a provider component
export const PaymentProvider = ({ children }) => {
  const [paymentData, setPaymentData] = useState({
    id:"",
    corpNo: "",
    siteName: "",
    address: "",
    city: "",
    province: "",
    postalCode: "",
    phone: "",
    email: "",
    sitepic: "",
    createdby_Empid: "",
    date_Created: "",

    supervisorTitle: "",
    supervisorProfit: "",
    supervisorPayrate: "",
    supervisorHours: "",
    supervisorStatHours: "",

    conciergeTitle: "",
    conciergeProfit: "",
    conciergePayrate: "",
    conciergeHours: "",
    conciergeStatHours: "",

    patrolTitle: "",
    patrolProfit: "",
    patrolPayrate: "",
    patrolHours: "",
    patrolStatHours: "",

    extraTitle1: "",
    extraProfit1: "",
    extraPayrate1: "",
    extraHours1: "",
    extraStatHours1: "",

    extraTitle2: "",
    extraProfit2: "",
    extraPayrate2: "",
    extraHours2: "",
    extraStatHours2: "",

    unionAmt: "",
    vacationAmt: "",
    additionalAmt: "",

    year2Percent: "",
    year3Percent: "",
    year4Percent: "",
    year5Percent: "",

    overheadAmt: "",
    cppPercent: "",
    eiPercent: "",
    wsibPercent: "",
    healthTax: "",
    vacationPay: "",

    hst: 13,
  });

  const [contextData, setContextData] = useState({
    s_TotalHoursYear: 0,

    s_LeapHours: 0,
    c_LeapHours: 0,
    p_LeapHours: 0,
    e1_LeapHours: 0,
    e2_LeapHours: 0,

    s_Payrate1: 0,
    s_BillingRate1: 0,
    s_StatBillingRate1: 0,
    s_billingYear1: 0,
    s_billingLeap1: 0,
    s_billingStat1: 0,
    s_billingTotal1: 0,
    s_totalYear1: 0,

    s_Payrate2: 0,
    s_BillingRate2: 0,
    s_StatBillingRate2: 0,
    s_billingYear2: 0,
    s_billingLeap2: 0,
    s_billingStat2: 0,
    s_billingTotal2: 0,
    s_totalYear2: 0,

    s_Payrate3: 0,
    s_BillingRate3: 0,
    s_StatBillingRate3: 0,
    s_billingYear3: 0,
    s_billingLeap3: 0,
    s_billingStat3: 0,
    s_billingTotal3: 0,
    s_totalYear3: 0,

    s_Payrate4: 0,
    s_BillingRate4: 0,
    s_StatBillingRate4: 0,
    s_billingYear4: 0,
    s_billingLeap4: 0,
    s_billingStat4: 0,
    s_billingTotal4: 0,
    s_totalYear4: 0,

    s_Payrate5: 0,
    s_BillingRate5: 0,
    s_StatBillingRate5: 0,
    s_billingYear5: 0,
    s_billingLeap5: 0,
    s_billingStat5: 0,
    s_billingTotal5: 0,
    s_totalYear5: 0,

    c_Payrate1: 0,
    c_BillingRate1: 0,
    c_StatBillingRate1: 0,
    c_billingYear1: 0,
    c_billingLeap1: 0,
    c_billingStat1: 0,
    c_billingTotal1: 0,
    c_totalYear1: 0,

    c_Payrate2: 0,
    c_BillingRate2: 0,
    c_StatBillingRate2: 0,
    c_billingYear2: 0,
    c_billingLeap2: 0,
    c_billingStat2: 0,
    c_billingTotal2: 0,
    c_totalYear2: 0,

    c_Payrate3: 0,
    c_BillingRate3: 0,
    c_StatBillingRate3: 0,
    c_billingYear3: 0,
    c_billingLeap3: 0,
    c_billingStat3: 0,
    c_billingTotal3: 0,
    c_totalYear3: 0,

    c_Payrate4: 0,
    c_BillingRate4: 0,
    c_StatBillingRate4: 0,
    c_billingYear4: 0,
    c_billingLeap4: 0,
    c_billingStat4: 0,
    c_billingTotal4: 0,
    c_totalYear4: 0,

    c_Payrate5: 0,
    c_BillingRate5: 0,
    c_StatBillingRate5: 0,
    c_billingYear5: 0,
    c_billingLeap5: 0,
    c_billingStat5: 0,
    c_billingTotal5: 0,
    c_totalYear5: 0,

    p_Payrate1: 0,
    p_BillingRate1: 0,
    p_StatBillingRate1: 0,
    p_billingYear1: 0,
    p_billingLeap1: 0,
    p_billingStat1: 0,
    p_billingTotal1: 0,
    p_totalYear1: 0,

    p_Payrate2: 0,
    p_BillingRate2: 0,
    p_StatBillingRate2: 0,
    p_billingYear2: 0,
    p_billingLeap2: 0,
    p_billingStat2: 0,
    p_billingTotal2: 0,
    p_totalYear2: 0,

    p_Payrate3: 0,
    p_BillingRate3: 0,
    p_StatBillingRate3: 0,
    p_billingYear3: 0,
    p_billingLeap3: 0,
    p_billingStat3: 0,
    p_billingTotal3: 0,
    p_totalYear3: 0,

    p_Payrate4: 0,
    p_BillingRate4: 0,
    p_StatBillingRate4: 0,
    p_billingYear4: 0,
    p_billingLeap4: 0,
    p_billingStat4: 0,
    p_billingTotal4: 0,
    p_totalYear4: 0,

    p_Payrate5: 0,
    p_BillingRate5: 0,
    p_StatBillingRate5: 0,
    p_billingYear5: 0,
    p_billingLeap5: 0,
    p_billingStat5: 0,
    p_billingTotal5: 0,
    p_totalYear5: 0,

    e1_Payrate1: 0,
    e1_BillingRate1: 0,
    e1_StatBillingRate1: 0,
    e1_billingYear1: 0,
    e1_billingLeap1: 0,
    e1_billingStat1: 0,
    e1_billingTotal1: 0,
    e1_totalYear1: 0,

    e1_Payrate2: 0,
    e1_BillingRate2: 0,
    e1_StatBillingRate2: 0,
    e1_billingYear2: 0,
    e1_billingLeap2: 0,
    e1_billingStat2: 0,
    e1_billingTotal2: 0,
    e1_totalYear2: 0,

    e1_Payrate3: 0,
    e1_BillingRate3: 0,
    e1_StatBillingRate3: 0,
    e1_billingYear3: 0,
    e1_billingLeap3: 0,
    e1_billingStat3: 0,
    e1_billingTotal3: 0,
    e1_totalYear3: 0,

    e1_Payrate4: 0,
    e1_BillingRate4: 0,
    e1_StatBillingRate4: 0,
    e1_billingYear4: 0,
    e1_billingLeap4: 0,
    e1_billingStat4: 0,
    e1_billingTotal4: 0,
    e1_totalYear4: 0,

    e1_Payrate5: 0,
    e1_BillingRate5: 0,
    e1_StatBillingRate5: 0,
    e1_billingYear5: 0,
    e1_billingLeap5: 0,
    e1_billingStat5: 0,
    e1_billingTotal5: 0,
    e1_totalYear5: 0,

    e2_Payrate1: 0,
    e2_BillingRate1: 0,
    e2_StatBillingRate1: 0,
    e2_billingYear1: 0,
    e2_billingLeap1: 0,
    e2_billingStat1: 0,
    e2_billingTotal1: 0,
    e2_totalYear1: 0,

    e2_Payrate2: 0,
    e2_BillingRate2: 0,
    e2_StatBillingRate2: 0,
    e2_billingYear2: 0,
    e2_billingLeap2: 0,
    e2_billingStat2: 0,
    e2_billingTotal2: 0,
    e2_totalYear2: 0,

    e2_Payrate3: 0,
    e2_BillingRate3: 0,
    e2_StatBillingRate3: 0,
    e2_billingYear3: 0,
    e2_billingLeap3: 0,
    e2_billingStat3: 0,
    e2_billingTotal3: 0,
    e2_totalYear3: 0,

    e2_Payrate4: 0,
    e2_BillingRate4: 0,
    e2_StatBillingRate4: 0,
    e2_billingYear4: 0,
    e2_billingLeap4: 0,
    e2_billingStat4: 0,
    e2_billingTotal4: 0,
    e2_totalYear4: 0,

    e2_Payrate5: 0,
    e2_BillingRate5: 0,
    e2_StatBillingRate5: 0,
    e2_billingYear5: 0,
    e2_billingLeap5: 0,
    e2_billingStat5: 0,
    e2_billingTotal5: 0,
    e2_totalYear5: 0,

    s_HoursYear: 0,
    s_LeapHoursYear: 0,
    s_StatHoursYear: 0,
    c_HoursYear: 0,
    c_LeapHoursYear: 0,
    c_StatHoursYear: 0,
    p_HoursYear: 0,
    p_LeapHoursYear: 0,
    p_StatHoursYear: 0,
    e1_HoursYear: 0,
    e1_LeapHoursYear: 0,
    e1_StatHoursYear: 0,
    e2_HoursYear: 0,
    e2_LeapHoursYear: 0,
    e2_StatHoursYear: 0,

    total1: 0,
    hst1: 0,
    grandTotal1: 0,
    monthlyTotal1: 0,
    s_payable1: 0,
    c_payable1: 0,
    p_payable1: 0,
    e1_payable1: 0,
    e2_payable1: 0,
    total_payable1: 0,

    total2: 0,
    hst2: 0,
    grandTotal2: 0,
    monthlyTotal2: 0,
    s_payable2: 0,
    c_payable2: 0,
    p_payable2: 0,
    e1_payable2: 0,
    e2_payable2: 0,
    total_payable2: 0,

    total3: 0,
    hst3: 0,
    grandTotal3: 0,
    monthlyTotal3: 0,
    s_payable3: 0,
    c_payable3: 0,
    p_payable3: 0,
    e1_payable3: 0,
    e2_payable3: 0,
    total_payable3: 0,

    total4: 0,
    hst4: 0,
    grandTotal4: 0,
    monthlyTotal4: 0,
    s_payable4: 0,
    c_payable4: 0,
    p_payable4: 0,
    e1_payable4: 0,
    e2_payable4: 0,
    total_payable4: 0,

    total5: 0,
    hst5: 0,
    grandTotal5: 0,
    monthlyTotal5: 0,
    s_payable5: 0,
    c_payable5: 0,
    p_payable5: 0,
    e1_payable5: 0,
    e2_payable5: 0,
    total_payable5: 0,
    
  });

  const calculateSupervisorPayrate = (values) => {
    const supervisorProfit = Number(values.supervisorProfit) || 0;
    const supervisorPayrate = Number(values.supervisorPayrate) || 0;
    const supervisorHours = Number(values.supervisorHours) || 0;
    const supervisorStatHours = Number(values.supervisorStatHours) || 0;

    const year2Percent = Number(values.year2Percent) || 0;
    const year3Percent = Number(values.year3Percent) || 0;
    const year4Percent = Number(values.year4Percent) || 0;
    const year5Percent = Number(values.year5Percent) || 0;

    const s_Payrate1 = supervisorPayrate;
    const s_Payrate2 = ((supervisorPayrate * year2Percent) / 100) * ((125 - supervisorProfit) / 100) + supervisorPayrate;
    const s_Payrate3 = ((s_Payrate2 * year3Percent) / 100) * ((125 - supervisorProfit) / 100) + s_Payrate2;
    const s_Payrate4 = ((s_Payrate3 * year4Percent) / 100) * ((125 - supervisorProfit) / 100) + s_Payrate3;
    const s_Payrate5 = ((s_Payrate4 * year5Percent) / 100) * ((125 - supervisorProfit) / 100) + s_Payrate4;

    const conciergeHours = Number(values.conciergeHours) || 0;
    const conciergeStatHours = Number(values.conciergeStatHours) || 0;
    const patrolHours = Number(values.patrolHours) || 0;
    const patrolStatHours = Number(values.patrolStatHours) || 0;

    const extraHours1 = Number(values.extraHours1) || 0;
    const extraStatHours1 = Number(values.extraStatHours1) || 0;

    const extraHours2 = Number(values.extraHours2) || 0;
    const extraStatHours2 = Number(values.extraStatHours2) || 0;

    const s_LeapHours = roundUp(6 * supervisorHours/168, 0);
    const c_LeapHours = roundDown(6 * conciergeHours/168, 0);
    const p_LeapHours = roundDown(6 * patrolHours/168, 0);
    const e1_LeapHours = roundDown(6 * extraHours1/168, 0);
    const e2_LeapHours = roundDown(6 * extraHours2/168, 0);


    const s_HoursYear = 52 * supervisorHours;
    const s_LeapHoursYear = s_LeapHours;
    const s_StatHoursYear = 9 * supervisorStatHours;

    const c_HoursYear = 52 * conciergeHours;
    const c_LeapHoursYear = c_LeapHours;
    const c_StatHoursYear = 9 * conciergeStatHours;

    const p_HoursYear = 52 * patrolHours;
    const p_LeapHoursYear = p_LeapHours;
    const p_StatHoursYear = 9 * patrolStatHours;
     
    const e1_HoursYear = 52 * extraHours1;
    const e1_LeapHoursYear = e1_LeapHours;
    const e1_StatHoursYear = 9 * extraStatHours1;

    const e2_HoursYear = 52 * extraHours2;
    const e2_LeapHoursYear = e2_LeapHours;
    const e2_StatHoursYear = 9 * extraStatHours2;
     
    const s_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    p_HoursYear + p_LeapHoursYear + p_StatHoursYear + e1_HoursYear + e1_LeapHoursYear + e1_StatHoursYear  + e2_HoursYear + e2_LeapHoursYear + e2_StatHoursYear;

    // const s_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    // p_HoursYear + p_LeapHoursYear + p_StatHoursYear;

    
    if (s_TotalHoursYear===0) return {}
    const s_BillingRate1 = supervisorPayrate * (1 + supervisorProfit / 100) + (values.additionalAmt/s_TotalHoursYear);
    const s_BillingRate2 = s_BillingRate1 * (1 + year2Percent / 100);
    const s_BillingRate3 = s_BillingRate2 * (1 + year3Percent / 100);
    const s_BillingRate4 = s_BillingRate3 * (1 + year4Percent / 100);
    const s_BillingRate5 = s_BillingRate4 * (1 + year5Percent / 100);

    const s_StatBillingRate1 = roundUp(1.5 * s_BillingRate1, 2) || 0;
    const s_StatBillingRate2 = roundUp(1.5 * s_BillingRate2, 2) || 0;
    const s_StatBillingRate3 = roundUp(1.5 * s_BillingRate3, 2) || 0;
    const s_StatBillingRate4 = roundUp(1.5 * s_BillingRate4, 2) || 0;
    const s_StatBillingRate5 = roundUp(1.5 * s_BillingRate5, 2) || 0;

    const s_billingYear1 = s_BillingRate1 * 52 * supervisorHours;
    const s_billingLeap1 = s_BillingRate1 * s_LeapHours;
    const s_billingStat1 = 1.5 * s_BillingRate1 * 9 * supervisorStatHours;
    const s_billingTotal1 = s_billingYear1 + s_billingLeap1 + s_billingStat1;

    const s_billingYear2 = s_BillingRate2 * 52 * supervisorHours;
    const s_billingLeap2 = s_BillingRate2 * s_LeapHours;
    const s_billingStat2 = 1.5 * s_BillingRate2 * 9 * supervisorStatHours;
    const s_billingTotal2 = s_billingYear2 + s_billingLeap2 + s_billingStat2;

    const s_billingYear3 = s_BillingRate3 * 52 * supervisorHours;
    const s_billingLeap3 = s_BillingRate3 * s_LeapHours;
    const s_billingStat3 = 1.5 * s_BillingRate3 * 9 * supervisorStatHours;
    const s_billingTotal3 = s_billingYear3 + s_billingLeap3 + s_billingStat3;

    const s_billingYear4 = s_BillingRate4 * 52 * supervisorHours;
    const s_billingLeap4 = s_BillingRate4 * s_LeapHours;
    const s_billingStat4 = 1.5 * s_BillingRate4 * 9 * supervisorStatHours;
    const s_billingTotal4 = s_billingYear4 + s_billingLeap4 + s_billingStat4;

    const s_billingYear5 = s_BillingRate5 * 52 * supervisorHours;
    const s_billingLeap5 = s_BillingRate5 * s_LeapHours;
    const s_billingStat5 = 1.5 * s_BillingRate5 * 9 * supervisorStatHours;
    const s_billingTotal5 = s_billingYear5 + s_billingLeap5 + s_billingStat5;

    return {
      s_TotalHoursYear,

      s_LeapHours,

      s_Payrate1,
      s_BillingRate1,
      s_StatBillingRate1,
      s_billingYear1,
      s_billingLeap1,
      s_billingStat1,
      s_billingTotal1,

      s_Payrate2,
      s_BillingRate2,
      s_StatBillingRate2,
      s_billingYear2,
      s_billingLeap2,
      s_billingStat2,
      s_billingTotal2,

      s_Payrate3,
      s_BillingRate3,
      s_StatBillingRate3,
      s_billingYear3,
      s_billingLeap3,
      s_billingStat3,
      s_billingTotal3,

      s_Payrate4,
      s_BillingRate4,
      s_StatBillingRate4,
      s_billingYear4,
      s_billingLeap4,
      s_billingStat4,
      s_billingTotal4,

      s_Payrate5,
      s_BillingRate5,
      s_StatBillingRate5,
      s_billingYear5,
      s_billingLeap5,
      s_billingStat5,
      s_billingTotal5,

      s_HoursYear,
      s_LeapHoursYear,
      s_StatHoursYear,
    };
  };

  const calculateConciergePayrate = (values) => {
    const conciergeProfit = Number(values.conciergeProfit) || 0;
    const conciergePayrate = Number(values.conciergePayrate) || 0;
    const conciergeHours = Number(values.conciergeHours) || 0;
    const conciergeStatHours = Number(values.conciergeStatHours) || 0;

    const year2Percent = Number(values.year2Percent) || 0;
    const year3Percent = Number(values.year3Percent) || 0;
    const year4Percent = Number(values.year4Percent) || 0;
    const year5Percent = Number(values.year5Percent) || 0;

    const c_Payrate1 = conciergePayrate;
    const c_Payrate2 = ((conciergePayrate * year2Percent) / 100) * ((125 - conciergeProfit) / 100) + conciergePayrate;
    const c_Payrate3 = ((c_Payrate2 * year3Percent) / 100) * ((125 - conciergeProfit) / 100) + c_Payrate2;
    const c_Payrate4 = ((c_Payrate3 * year4Percent) / 100) * ((125 - conciergeProfit) / 100) + c_Payrate3;
    const c_Payrate5 = ((c_Payrate4 * year5Percent) / 100) * ((125 - conciergeProfit) / 100) + c_Payrate4;

    const supervisorHours = Number(values.supervisorHours) || 0;
    const supervisorStatHours = Number(values.supervisorStatHours) || 0;
    const patrolHours = Number(values.patrolHours) || 0;
    const patrolStatHours = Number(values.patrolStatHours) || 0;

    const extraHours1 = Number(values.extraHours1) || 0;
    const extraStatHours1 = Number(values.extraStatHours1) || 0;

    const extraHours2 = Number(values.extraHours2) || 0;
    const extraStatHours2 = Number(values.extraStatHours2) || 0;

    const s_LeapHours = roundUp(6 * supervisorHours / 168, 0);
    const c_LeapHours = roundDown(6 * conciergeHours / 168, 0);
    const p_LeapHours = roundDown(6 * patrolHours / 168, 0);
    const e1_LeapHours = roundDown(6 * extraHours1/168, 0);
    const e2_LeapHours = roundDown(6 * extraHours2/168, 0);

    const s_HoursYear = 52 * supervisorHours;
    const s_LeapHoursYear = s_LeapHours;
    const s_StatHoursYear = 9 * supervisorStatHours;

    const c_HoursYear = 52 * conciergeHours;
    const c_LeapHoursYear = c_LeapHours;
    const c_StatHoursYear = 9 * conciergeStatHours;

    const p_HoursYear = 52 * patrolHours;
    const p_LeapHoursYear = p_LeapHours;
    const p_StatHoursYear = 9 * patrolStatHours;

    const e1_HoursYear = 52 * extraHours1;
    const e1_LeapHoursYear = e1_LeapHours;
    const e1_StatHoursYear = 9 * extraStatHours1;

    const e2_HoursYear = 52 * extraHours2;
    const e2_LeapHoursYear = e2_LeapHours;
    const e2_StatHoursYear = 9 * extraStatHours2;
     
    const c_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    p_HoursYear + p_LeapHoursYear + p_StatHoursYear + e1_HoursYear + e1_LeapHoursYear + e1_StatHoursYear  + e2_HoursYear + e2_LeapHoursYear + e2_StatHoursYear;

    // const c_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    // p_HoursYear + p_LeapHoursYear + p_StatHoursYear;

    if (c_TotalHoursYear===0) return {}
    const c_BillingRate1 = conciergePayrate * (1 + conciergeProfit / 100) + (values.additionalAmt/c_TotalHoursYear);
    const c_BillingRate2 = c_BillingRate1 * (1 + year2Percent / 100);
    const c_BillingRate3 = c_BillingRate2 * (1 + year3Percent / 100);
    const c_BillingRate4 = c_BillingRate3 * (1 + year4Percent / 100);
    const c_BillingRate5 = c_BillingRate4 * (1 + year5Percent / 100);

    const c_StatBillingRate1 = roundUp(1.5 * c_BillingRate1, 2) || 0;
    const c_StatBillingRate2 = roundUp(1.5 * c_BillingRate2, 2) || 0;
    const c_StatBillingRate3 = roundUp(1.5 * c_BillingRate3, 2) || 0;
    const c_StatBillingRate4 = roundUp(1.5 * c_BillingRate4, 2) || 0;
    const c_StatBillingRate5 = roundUp(1.5 * c_BillingRate5, 2) || 0;
    

    const c_billingYear1 = c_BillingRate1 * 52 * conciergeHours;
    const c_billingLeap1 = c_BillingRate1 * c_LeapHours;
    const c_billingStat1 = 1.5 * c_BillingRate1 * 9 * conciergeStatHours;
    const c_billingTotal1 = c_billingYear1 + c_billingLeap1 + c_billingStat1;

    const c_billingYear2 = c_BillingRate2 * 52 * conciergeHours;
    const c_billingLeap2 = c_BillingRate2 * c_LeapHours;
    const c_billingStat2 = 1.5 * c_BillingRate2 * 9 * conciergeStatHours;
    const c_billingTotal2 = c_billingYear2 + c_billingLeap2 + c_billingStat2;

    const c_billingYear3 = c_BillingRate3 * 52 * conciergeHours;
    const c_billingLeap3 = c_BillingRate3 * c_LeapHours;
    const c_billingStat3 = 1.5 * c_BillingRate3 * 9 * conciergeStatHours;
    const c_billingTotal3 = c_billingYear3 + c_billingLeap3 + c_billingStat3;

    const c_billingYear4 = c_BillingRate4 * 52 * conciergeHours;
    const c_billingLeap4 = c_BillingRate4 * c_LeapHours;
    const c_billingStat4 = 1.5 * c_BillingRate4 * 9 * conciergeStatHours;
    const c_billingTotal4 = c_billingYear4 + c_billingLeap4 + c_billingStat4;

    const c_billingYear5 = c_BillingRate5 * 52 * conciergeHours;
    const c_billingLeap5 = c_BillingRate5 * c_LeapHours;
    const c_billingStat5 = 1.5 * c_BillingRate5 * 9 * conciergeStatHours;
    const c_billingTotal5 = c_billingYear5 + c_billingLeap5 + c_billingStat5;

    return {
      c_LeapHours,

      c_Payrate1,
      c_BillingRate1,
      c_StatBillingRate1,
      c_billingYear1,
      c_billingLeap1,
      c_billingStat1,
      c_billingTotal1,

      c_Payrate2,
      c_BillingRate2,
      c_StatBillingRate2,
      c_billingYear2,
      c_billingLeap2,
      c_billingStat2,
      c_billingTotal2,

      c_Payrate3,
      c_BillingRate3,
      c_StatBillingRate3,
      c_billingYear3,
      c_billingLeap3,
      c_billingStat3,
      c_billingTotal3,

      c_Payrate4,
      c_BillingRate4,
      c_StatBillingRate4,
      c_billingYear4,
      c_billingLeap4,
      c_billingStat4,
      c_billingTotal4,

      c_Payrate5,
      c_BillingRate5,
      c_StatBillingRate5,
      c_billingYear5,
      c_billingLeap5,
      c_billingStat5,
      c_billingTotal5,

      c_HoursYear,
      c_LeapHoursYear,
      c_StatHoursYear,
    };
  };

  const calculatePatrolPayrate = (values) => {
    const patrolProfit = Number(values.patrolProfit) || 0;
    const patrolPayrate = Number(values.patrolPayrate) || 0;
    const patrolHours = Number(values.patrolHours) || 0;
    const patrolStatHours = Number(values.patrolStatHours) || 0;

    const year2Percent = Number(values.year2Percent) || 0;
    const year3Percent = Number(values.year3Percent) || 0;
    const year4Percent = Number(values.year4Percent) || 0;
    const year5Percent = Number(values.year5Percent) || 0;

    const p_Payrate1 = patrolPayrate;
    const p_Payrate2 = ((patrolPayrate * year2Percent) / 100) * ((125 - patrolProfit) / 100) + patrolPayrate;
    const p_Payrate3 = ((p_Payrate2 * year3Percent) / 100) * ((125 - patrolProfit) / 100) + p_Payrate2;
    const p_Payrate4 = ((p_Payrate3 * year4Percent) / 100) * ((125 - patrolProfit) / 100) + p_Payrate3;
    const p_Payrate5 = ((p_Payrate4 * year5Percent) / 100) * ((125 - patrolProfit) / 100) + p_Payrate4;

    const supervisorHours = Number(values.supervisorHours) || 0;
    const supervisorStatHours = Number(values.supervisorStatHours) || 0;
    const conciergeHours = Number(values.conciergeHours) || 0;
    const conciergeStatHours = Number(values.conciergeStatHours) || 0;

    const extraHours1 = Number(values.extraHours1) || 0;
    const extraStatHours1 = Number(values.extraStatHours1) || 0;

    const extraHours2 = Number(values.extraHours2) || 0;
    const extraStatHours2 = Number(values.extraStatHours2) || 0;

    const s_LeapHours = roundUp(6 * supervisorHours / 168, 0);
    const c_LeapHours = roundDown(6 * conciergeHours / 168, 0);
    const p_LeapHours = roundDown(6 * patrolHours / 168, 0);
    const e1_LeapHours = roundDown(6 * extraHours1/168, 0);
    const e2_LeapHours = roundDown(6 * extraHours2/168, 0);

    const s_HoursYear = 52 * supervisorHours;
    const s_LeapHoursYear = s_LeapHours;
    const s_StatHoursYear = 9 * supervisorStatHours;

    const c_HoursYear = 52 * conciergeHours;
    const c_LeapHoursYear = c_LeapHours;
    const c_StatHoursYear = 9 * conciergeStatHours;

    const p_HoursYear = 52 * patrolHours;
    const p_LeapHoursYear = p_LeapHours;
    const p_StatHoursYear = 9 * patrolStatHours;
     
    const e1_HoursYear = 52 * extraHours1;
    const e1_LeapHoursYear = e1_LeapHours;
    const e1_StatHoursYear = 9 * extraStatHours1;

    const e2_HoursYear = 52 * extraHours2;
    const e2_LeapHoursYear = e2_LeapHours;
    const e2_StatHoursYear = 9 * extraStatHours2;
     
    const p_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    p_HoursYear + p_LeapHoursYear + p_StatHoursYear + e1_HoursYear + e1_LeapHoursYear + e1_StatHoursYear  + e2_HoursYear + e2_LeapHoursYear + e2_StatHoursYear;

    // const p_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    // p_HoursYear + p_LeapHoursYear + p_StatHoursYear;

    if (p_TotalHoursYear===0) return {}
    const p_BillingRate1 = patrolPayrate * (1 + patrolProfit / 100) + (values.additionalAmt/p_TotalHoursYear);
    const p_BillingRate2 = p_BillingRate1 * (1 + year2Percent / 100);
    const p_BillingRate3 = p_BillingRate2 * (1 + year3Percent / 100);
    const p_BillingRate4 = p_BillingRate3 * (1 + year4Percent / 100);
    const p_BillingRate5 = p_BillingRate4 * (1 + year5Percent / 100);

    const p_StatBillingRate1 = roundUp(1.5 * p_BillingRate1, 2) || 0;
    const p_StatBillingRate2 = roundUp(1.5 * p_BillingRate2, 2) || 0;
    const p_StatBillingRate3 = roundUp(1.5 * p_BillingRate3, 2) || 0;
    const p_StatBillingRate4 = roundUp(1.5 * p_BillingRate4, 2) || 0;
    const p_StatBillingRate5 = roundUp(1.5 * p_BillingRate5, 2) || 0;

    const p_billingYear1 = p_BillingRate1 * 52 * patrolHours;
    const p_billingLeap1 = p_BillingRate1 * p_LeapHours;
    const p_billingStat1 = 1.5 * p_BillingRate1 * 9 * patrolStatHours;
    const p_billingTotal1 = p_billingYear1 + p_billingLeap1 + p_billingStat1;

    const p_billingYear2 = p_BillingRate2 * 52 * patrolHours;
    const p_billingLeap2 = p_BillingRate2 * p_LeapHours;
    const p_billingStat2 = 1.5 * p_BillingRate2 * 9 * patrolStatHours;
    const p_billingTotal2 = p_billingYear2 + p_billingLeap2 + p_billingStat2;

    const p_billingYear3 = p_BillingRate3 * 52 * patrolHours;
    const p_billingLeap3 = p_BillingRate3 * p_LeapHours;
    const p_billingStat3 = 1.5 * p_BillingRate3 * 9 * patrolStatHours;
    const p_billingTotal3 = p_billingYear3 + p_billingLeap3 + p_billingStat3;

    const p_billingYear4 = p_BillingRate4 * 52 * patrolHours;
    const p_billingLeap4 = p_BillingRate4 * p_LeapHours;
    const p_billingStat4 = 1.5 * p_BillingRate4 * 9 * patrolStatHours;
    const p_billingTotal4 = p_billingYear4 + p_billingLeap4 + p_billingStat4;

    const p_billingYear5 = p_BillingRate5 * 52 * patrolHours;
    const p_billingLeap5 = p_BillingRate5 * p_LeapHours;
    const p_billingStat5 = 1.5 * p_BillingRate5 * 9 * patrolStatHours;
    const p_billingTotal5 = p_billingYear5 + p_billingLeap5 + p_billingStat5;

    return {
      p_LeapHours,

      p_Payrate1,
      p_BillingRate1,
      p_StatBillingRate1,
      p_billingYear1,
      p_billingLeap1,
      p_billingStat1,
      p_billingTotal1,

      p_Payrate2,
      p_BillingRate2,
      p_StatBillingRate2,
      p_billingYear2,
      p_billingLeap2,
      p_billingStat2,
      p_billingTotal2,

      p_Payrate3,
      p_BillingRate3,
      p_StatBillingRate3,
      p_billingYear3,
      p_billingLeap3,
      p_billingStat3,
      p_billingTotal3,

      p_Payrate4,
      p_BillingRate4,
      p_StatBillingRate4,
      p_billingYear4,
      p_billingLeap4,
      p_billingStat4,
      p_billingTotal4,

      p_Payrate5,
      p_BillingRate5,
      p_StatBillingRate5,
      p_billingYear5,
      p_billingLeap5,
      p_billingStat5,
      p_billingTotal5,

      p_HoursYear,
      p_LeapHoursYear,
      p_StatHoursYear,
    };
  };

  const calculateExtra1Payrate = (values) => {
    const extraProfit1 = Number(values.extraProfit1) || 0;
    const extraPayrate1 = Number(values.extraPayrate1) || 0;
    const extraHours1 = Number(values.extraHours1) || 0;
    const extraStatHours1 = Number(values.extraStatHours1) || 0;

    const year2Percent = Number(values.year2Percent) || 0;
    const year3Percent = Number(values.year3Percent) || 0;
    const year4Percent = Number(values.year4Percent) || 0;
    const year5Percent = Number(values.year5Percent) || 0;

    const e1_Payrate1 = extraPayrate1;
    const e1_Payrate2 = ((extraPayrate1 * year2Percent) / 100) * ((125 - extraProfit1) / 100) + extraPayrate1;
    const e1_Payrate3 = ((e1_Payrate2 * year3Percent) / 100) * ((125 -extraProfit1) / 100) + e1_Payrate2;
    const e1_Payrate4 = ((e1_Payrate3 * year4Percent) / 100) * ((125 -extraProfit1) / 100) + e1_Payrate3;
    const e1_Payrate5 = ((e1_Payrate4 * year5Percent) / 100) * ((125 -extraProfit1) / 100) + e1_Payrate4;

    const supervisorHours = Number(values.supervisorHours) || 0;
    const supervisorStatHours = Number(values.supervisorStatHours) || 0;
    const conciergeHours = Number(values.conciergeHours) || 0;
    const conciergeStatHours = Number(values.conciergeStatHours) || 0;
    const patrolHours = Number(values.patrolHours) || 0;
    const patrolStatHours = Number(values.patrolStatHours) || 0;
    const extraHours2 = Number(values.extraHours2) || 0;
    const extraStatHours2 = Number(values.extraStatHours2) || 0;

    const s_LeapHours = roundUp(6 * supervisorHours/168, 0);
    const c_LeapHours = roundDown(6 * conciergeHours/168, 0);
    const p_LeapHours = roundDown(6 * patrolHours/168, 0);
    const e1_LeapHours = roundDown(6 * extraHours1/168, 0);
    const e2_LeapHours = roundDown(6 * extraHours2/168, 0);


    const s_HoursYear = 52 * supervisorHours;
    const s_LeapHoursYear = s_LeapHours;
    const s_StatHoursYear = 9 * supervisorStatHours;

    const c_HoursYear = 52 * conciergeHours;
    const c_LeapHoursYear = c_LeapHours;
    const c_StatHoursYear = 9 * conciergeStatHours;

    const p_HoursYear = 52 * patrolHours;
    const p_LeapHoursYear = p_LeapHours;
    const p_StatHoursYear = 9 * patrolStatHours;

    const e1_HoursYear = 52 * extraHours1;
    const e1_LeapHoursYear = e1_LeapHours;
    const e1_StatHoursYear = 9 * extraStatHours1;

    const e2_HoursYear = 52 * extraHours2;
    const e2_LeapHoursYear = e2_LeapHours;
    const e2_StatHoursYear = 9 * extraStatHours2;
     
    const e1_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    p_HoursYear + p_LeapHoursYear + p_StatHoursYear + e1_HoursYear + e1_LeapHoursYear + e1_StatHoursYear  + e2_HoursYear + e2_LeapHoursYear + e2_StatHoursYear;

    if (e1_TotalHoursYear===0) return {}
    const e1_BillingRate1 = extraPayrate1 * (1 + extraProfit1 / 100) + (values.additionalAmt/e1_TotalHoursYear);
    const e1_BillingRate2 = e1_BillingRate1 * (1 + year2Percent / 100);
    const e1_BillingRate3 = e1_BillingRate2 * (1 + year3Percent / 100);
    const e1_BillingRate4 = e1_BillingRate3 * (1 + year4Percent / 100);
    const e1_BillingRate5 = e1_BillingRate4 * (1 + year5Percent / 100);

    const e1_StatBillingRate1 = roundUp(1.5 * e1_BillingRate1, 2) || 0;
    const e1_StatBillingRate2 = roundUp(1.5 * e1_BillingRate2, 2) || 0;
    const e1_StatBillingRate3 = roundUp(1.5 * e1_BillingRate3, 2) || 0;
    const e1_StatBillingRate4 = roundUp(1.5 * e1_BillingRate4, 2) || 0;
    const e1_StatBillingRate5 = roundUp(1.5 * e1_BillingRate5, 2) || 0;

    const e1_billingYear1 = e1_BillingRate1 * 52 * extraHours1;
    const e1_billingLeap1 = e1_BillingRate1 * e1_LeapHours;
    const e1_billingStat1 = 1.5 * e1_BillingRate1 * 9 * extraStatHours1;
    const e1_billingTotal1 = e1_billingYear1 + e1_billingLeap1 + e1_billingStat1;

    const e1_billingYear2 = e1_BillingRate2 * 52 * extraHours1;
    const e1_billingLeap2 = e1_BillingRate2 * e1_LeapHours;
    const e1_billingStat2 = 1.5 * e1_BillingRate2 * 9 * extraStatHours1;
    const e1_billingTotal2 = e1_billingYear2 + e1_billingLeap2 + e1_billingStat2;

    const e1_billingYear3 = e1_BillingRate3 * 52 * extraHours1;
    const e1_billingLeap3 = e1_BillingRate3 * e1_LeapHours;
    const e1_billingStat3 = 1.5 * e1_BillingRate3 * 9 * extraStatHours1;
    const e1_billingTotal3 = e1_billingYear3 + e1_billingLeap3 + e1_billingStat3;

    const e1_billingYear4= e1_BillingRate4 * 52 * extraHours1;
    const e1_billingLeap4 = e1_BillingRate4 * e1_LeapHours;
    const e1_billingStat4 = 1.5 * e1_BillingRate4 * 9 * extraStatHours1;
    const e1_billingTotal4 = e1_billingYear4 + e1_billingLeap4 + e1_billingStat4;

    const e1_billingYear5= e1_BillingRate5 * 52 * extraHours1;
    const e1_billingLeap5 = e1_BillingRate5 * e1_LeapHours;
    const e1_billingStat5 = 1.5 * e1_BillingRate5 * 9 * extraStatHours1;
    const e1_billingTotal5 = e1_billingYear5 + e1_billingLeap5 + e1_billingStat5;

    return {
      e1_LeapHours,

      e1_Payrate1,
      e1_BillingRate1,
      e1_StatBillingRate1,
      e1_billingYear1,
      e1_billingLeap1,
      e1_billingStat1,
      e1_billingTotal1,

      e1_Payrate2,
      e1_BillingRate2,
      e1_StatBillingRate2,
      e1_billingYear2,
      e1_billingLeap2,
      e1_billingStat2,
      e1_billingTotal2,

      e1_Payrate3,
      e1_BillingRate3,
      e1_StatBillingRate3,
      e1_billingYear3,
      e1_billingLeap3,
      e1_billingStat3,
      e1_billingTotal3,

      e1_Payrate4,
      e1_BillingRate4,
      e1_StatBillingRate4,
      e1_billingYear4,
      e1_billingLeap4,
      e1_billingStat4,
      e1_billingTotal4,

      e1_Payrate5,
      e1_BillingRate5,
      e1_StatBillingRate5,
      e1_billingYear5,
      e1_billingLeap5,
      e1_billingStat5,
      e1_billingTotal5,

      e1_HoursYear,
      e1_LeapHoursYear,
      e1_StatHoursYear,
    };
  };

  const calculateExtra2Payrate = (values) => {
    const extraProfit2 = Number(values.extraProfit2) || 0;
    const extraPayrate2 = Number(values.extraPayrate2) || 0;
    const extraHours2 = Number(values.extraHours2) || 0;
    const extraStatHours2 = Number(values.extraStatHours2) || 0;

    const year2Percent = Number(values.year2Percent) || 0;
    const year3Percent = Number(values.year3Percent) || 0;
    const year4Percent = Number(values.year4Percent) || 0;
    const year5Percent = Number(values.year5Percent) || 0;

    const e2_Payrate1 = extraPayrate2;
    const e2_Payrate2 = ((extraPayrate2 * year2Percent) / 100) * ((125 - extraProfit2) / 100) + extraPayrate2;
    const e2_Payrate3 = ((e2_Payrate2 * year3Percent) / 100) * ((125 -extraProfit2) / 100) + e2_Payrate2;
    const e2_Payrate4 = ((e2_Payrate3 * year4Percent) / 100) * ((125 -extraProfit2) / 100) + e2_Payrate3;
    const e2_Payrate5 = ((e2_Payrate4 * year5Percent) / 100) * ((125 -extraProfit2) / 100) + e2_Payrate4;

    const supervisorHours = Number(values.supervisorHours) || 0;
    const supervisorStatHours = Number(values.supervisorStatHours) || 0;
    const conciergeHours = Number(values.conciergeHours) || 0;
    const conciergeStatHours = Number(values.conciergeStatHours) || 0;
    const patrolHours = Number(values.patrolHours) || 0;
    const patrolStatHours = Number(values.patrolStatHours) || 0;
    const extraHours1 = Number(values.extraHours1) || 0;
    const extraStatHours1 = Number(values.extraStatHours1) || 0;

    const s_LeapHours = roundUp(6 * supervisorHours/168, 0);
    const c_LeapHours = roundDown(6 * conciergeHours/168, 0);
    const p_LeapHours = roundDown(6 * patrolHours/168, 0);
    const e1_LeapHours = roundDown(6 * extraHours1/168, 0);
    const e2_LeapHours = roundDown(6 * extraHours2/168, 0);


    const s_HoursYear = 52 * supervisorHours;
    const s_LeapHoursYear = s_LeapHours;
    const s_StatHoursYear = 9 * supervisorStatHours;

    const c_HoursYear = 52 * conciergeHours;
    const c_LeapHoursYear = c_LeapHours;
    const c_StatHoursYear = 9 * conciergeStatHours;

    const p_HoursYear = 52 * patrolHours;
    const p_LeapHoursYear = p_LeapHours;
    const p_StatHoursYear = 9 * patrolStatHours;

    const e1_HoursYear = 52 * extraHours1;
    const e1_LeapHoursYear = e1_LeapHours;
    const e1_StatHoursYear = 9 * extraStatHours1;

    const e2_HoursYear = 52 * extraHours2;
    const e2_LeapHoursYear = e2_LeapHours;
    const e2_StatHoursYear = 9 * extraStatHours2;
     
    const e2_TotalHoursYear = s_HoursYear + s_LeapHoursYear + s_StatHoursYear + c_HoursYear + c_LeapHoursYear + c_StatHoursYear +
    p_HoursYear + p_LeapHoursYear + p_StatHoursYear + e1_HoursYear + e1_LeapHoursYear + e1_StatHoursYear  + e2_HoursYear + e2_LeapHoursYear + e2_StatHoursYear;

    if (e2_TotalHoursYear===0) return {}
    const e2_BillingRate1 = extraPayrate2 * (1 + extraProfit2 / 100) + (values.additionalAmt/e2_TotalHoursYear);
    const e2_BillingRate2 = e2_BillingRate1 * (1 + year2Percent / 100);
    const e2_BillingRate3 = e2_BillingRate2 * (1 + year3Percent / 100);
    const e2_BillingRate4 = e2_BillingRate3 * (1 + year4Percent / 100);
    const e2_BillingRate5 = e2_BillingRate4 * (1 + year5Percent / 100);

    const e2_StatBillingRate1 = roundUp(1.5 * e2_BillingRate1, 2) || 0;
    const e2_StatBillingRate2 = roundUp(1.5 * e2_BillingRate2, 2) || 0;
    const e2_StatBillingRate3 = roundUp(1.5 * e2_BillingRate3, 2) || 0;
    const e2_StatBillingRate4 = roundUp(1.5 * e2_BillingRate4, 2) || 0;
    const e2_StatBillingRate5 = roundUp(1.5 * e2_BillingRate5, 2) || 0;

    const e2_billingYear1 = e2_BillingRate1 * 52 * extraHours2;
    const e2_billingLeap1 = e2_BillingRate1 * e2_LeapHours;
    const e2_billingStat1 = 1.5 * e2_BillingRate1 * 9 * extraStatHours2;
    const e2_billingTotal1 = e2_billingYear1 + e2_billingLeap1 + e2_billingStat1;

    const e2_billingYear2 = e2_BillingRate2 * 52 * extraHours2;
    const e2_billingLeap2 = e2_BillingRate2 * e2_LeapHours;
    const e2_billingStat2 = 1.5 * e2_BillingRate2 * 9 * extraStatHours1;
    const e2_billingTotal2 = e2_billingYear2 + e2_billingLeap2 + e2_billingStat2;

    const e2_billingYear3 = e2_BillingRate3 * 52 * extraHours2;
    const e2_billingLeap3 = e2_BillingRate3 * e2_LeapHours;
    const e2_billingStat3 = 1.5 * e2_BillingRate3 * 9 * extraStatHours2;
    const e2_billingTotal3 = e2_billingYear3 + e2_billingLeap3 + e2_billingStat3;

    const e2_billingYear4 = e2_BillingRate4 * 52 * extraHours2;
    const e2_billingLeap4 = e2_BillingRate4 * e2_LeapHours;
    const e2_billingStat4 = 1.5 * e2_BillingRate4 * 9 * extraStatHours2;
    const e2_billingTotal4 = e2_billingYear4 + e2_billingLeap4 + e2_billingStat4;

    const e2_billingYear5 = e2_BillingRate5 * 52 * extraHours2;
    const e2_billingLeap5 = e2_BillingRate5 * e2_LeapHours;
    const e2_billingStat5 = 1.5 * e2_BillingRate5 * 9 * extraStatHours2;
    const e2_billingTotal5 = e2_billingYear5 + e2_billingLeap5 + e2_billingStat5;

    return {
      e2_LeapHours,

      e2_Payrate1,
      e2_BillingRate1,
      e2_StatBillingRate1,
      e2_billingYear1,
      e2_billingLeap1,
      e2_billingStat1,
      e2_billingTotal1,

      e2_Payrate2,
      e2_BillingRate2,
      e2_StatBillingRate2,
      e2_billingYear2,
      e2_billingLeap2,
      e2_billingStat2,
      e2_billingTotal2,

      e2_Payrate3,
      e2_BillingRate3,
      e2_StatBillingRate3,
      e2_billingYear3,
      e2_billingLeap3,
      e2_billingStat3,
      e2_billingTotal3,

      e2_Payrate4,
      e2_BillingRate4,
      e2_StatBillingRate4,
      e2_billingYear4,
      e2_billingLeap4,
      e2_billingStat4,
      e2_billingTotal4,

      e2_Payrate5,
      e2_BillingRate5,
      e2_StatBillingRate5,
      e2_billingYear5,
      e2_billingLeap5,
      e2_billingStat5,
      e2_billingTotal5,

      e2_HoursYear,
      e2_LeapHoursYear,
      e2_StatHoursYear,
    };
  };

  const calculateTotal = (values, contextData) => {
    const total1 =
      contextData.s_billingTotal1 +
      contextData.c_billingTotal1 +
      contextData.p_billingTotal1 + contextData.e1_billingTotal1 + contextData.e2_billingTotal1 
      + Number(values.unionAmt) + Number(values.vacationAmt);
    const total2 =
      contextData.s_billingTotal2 +
      contextData.c_billingTotal2 +
      contextData.p_billingTotal2 + contextData.e1_billingTotal2 + contextData.e2_billingTotal2
      + Number(values.unionAmt) + Number(values.vacationAmt);
    const total3 =
      contextData.s_billingTotal3 +
      contextData.c_billingTotal3 +
      contextData.p_billingTotal3 + contextData.e1_billingTotal3 + contextData.e2_billingTotal3
      + Number(values.unionAmt) + Number(values.vacationAmt);

      const total4 =
      contextData.s_billingTotal4 +
      contextData.c_billingTotal4 +
      contextData.p_billingTotal4 + contextData.e1_billingTotal4 + contextData.e2_billingTotal4
      + Number(values.unionAmt) + Number(values.vacationAmt);

      const total5 =
      contextData.s_billingTotal5 +
      contextData.c_billingTotal5 +
      contextData.p_billingTotal5 + contextData.e1_billingTotal5 + contextData.e2_billingTotal5
      + Number(values.unionAmt) + Number(values.vacationAmt);

    const hst1 = (total1 * values.hst) / 100;
    const hst2 = (total2 * values.hst) / 100;
    const hst3 = (total3 * values.hst) / 100;
    const hst4 = (total4 * values.hst) / 100;
    const hst5 = (total5 * values.hst) / 100;

    const grandTotal1= Number(total1) + Number(hst1);
    const grandTotal2= Number(total2) + Number(hst2);
    const grandTotal3= Number(total3) + Number(hst3);
    const grandTotal4= Number(total4) + Number(hst4);
    const grandTotal5= Number(total5) + Number(hst5);

    const monthlyTotal1= grandTotal1/12;
    const monthlyTotal2= grandTotal2/12;
    const monthlyTotal3= grandTotal3/12;
    const monthlyTotal4= grandTotal4/12;
    const monthlyTotal5= grandTotal5/12;

    const s_payable1 = 
    (contextData.s_Payrate1 * 52 * values.supervisorHours) + 
    (contextData.s_Payrate1 * contextData.s_LeapHours) + 
    (contextData.s_Payrate1 * 1.5 * 9 * values.supervisorStatHours);

    const c_payable1 = 
    (contextData.c_Payrate1 * 52 * values.conciergeHours) + 
    (contextData.c_Payrate1 * contextData.c_LeapHours) + 
    (contextData.c_Payrate1 * 1.5 * 9 * values.conciergeStatHours);

    const p_payable1 = 
    (contextData.p_Payrate1 * 52 * values.patrolHours) + 
    (contextData.p_Payrate1 * contextData.p_LeapHours) + 
    (contextData.p_Payrate1 * 1.5 * 9 * values.patrolStatHours);

    const e1_payable1 = 
    (contextData.e1_Payrate1 * 52 * values.extraHours1) + 
    (contextData.e1_Payrate1 * contextData.e1_LeapHours) + 
    (contextData.e1_Payrate1 * 1.5 * 9 * values.extraStatHours1);

    const e2_payable1 = 
    (contextData.e2_Payrate1 * 52 * values.extraHours2) + 
    (contextData.e2_Payrate1 * contextData.e2_LeapHours) + 
    (contextData.e2_Payrate1 * 1.5 * 9 * values.extraStatHours2);


    const total_payable1 = s_payable1 + c_payable1 + p_payable1 + e1_payable1 + e2_payable1;

    const s_payable2 = 
    (contextData.s_Payrate2 * 52 * values.supervisorHours) + 
    (contextData.s_Payrate2 * contextData.s_LeapHours) + 
    (contextData.s_Payrate2 * 1.5 * 9 * values.supervisorStatHours);

    const c_payable2 = 
    (contextData.c_Payrate2 * 52 * values.conciergeHours) + 
    (contextData.c_Payrate2 * contextData.c_LeapHours) + 
    (contextData.c_Payrate2 * 1.5 * 9 * values.conciergeStatHours);

    const p_payable2 = 
    (contextData.p_Payrate2 * 52 * values.patrolHours) + 
    (contextData.p_Payrate2 * contextData.p_LeapHours) + 
    (contextData.p_Payrate2 * 1.5 * 9 * values.patrolStatHours);

    const e1_payable2 = 
    (contextData.e1_Payrate2 * 52 * values.extraHours1) + 
    (contextData.e1_Payrate2 * contextData.e1_LeapHours) + 
    (contextData.e1_Payrate2 * 1.5 * 9 * values.extraStatHours1);

    const e2_payable2 = 
    (contextData.e2_Payrate2 * 52 * values.extraHours2) + 
    (contextData.e2_Payrate2 * contextData.e2_LeapHours) + 
    (contextData.e2_Payrate2 * 1.5 * 9 * values.extraStatHours2);

    const total_payable2 = s_payable2 + c_payable2 + p_payable2 + e1_payable2 + e2_payable2;

    const s_payable3 = 
    (contextData.s_Payrate3 * 52 * values.supervisorHours) + 
    (contextData.s_Payrate3 * contextData.s_LeapHours) + 
    (contextData.s_Payrate3 * 1.5 * 9 * values.supervisorStatHours);

    const c_payable3 = 
    (contextData.c_Payrate3 * 52 * values.conciergeHours) + 
    (contextData.c_Payrate3 * contextData.c_LeapHours) + 
    (contextData.c_Payrate3 * 1.5 * 9 * values.conciergeStatHours);

    const p_payable3 = 
    (contextData.p_Payrate3 * 52 * values.patrolHours) + 
    (contextData.p_Payrate3 * contextData.p_LeapHours) + 
    (contextData.p_Payrate3 * 1.5 * 9 * values.patrolStatHours);

    const e1_payable3 = 
    (contextData.e1_Payrate3 * 52 * values.extraHours1) + 
    (contextData.e1_Payrate3 * contextData.e1_LeapHours) + 
    (contextData.e1_Payrate3 * 1.5 * 9 * values.extraStatHours1);

    const e2_payable3 = 
    (contextData.e2_Payrate3 * 52 * values.extraHours2) + 
    (contextData.e2_Payrate3 * contextData.e2_LeapHours) + 
    (contextData.e2_Payrate3 * 1.5 * 9 * values.extraStatHours2);


    const total_payable3 = s_payable3 + c_payable3 + p_payable3 + e1_payable3 + e2_payable3;

    const s_payable4 = 
    (contextData.s_Payrate4 * 52 * values.supervisorHours) + 
    (contextData.s_Payrate4 * contextData.s_LeapHours) + 
    (contextData.s_Payrate4 * 1.5 * 9 * values.supervisorStatHours);

    const c_payable4 = 
    (contextData.c_Payrate4 * 52 * values.conciergeHours) + 
    (contextData.c_Payrate4 * contextData.c_LeapHours) + 
    (contextData.c_Payrate4 * 1.5 * 9 * values.conciergeStatHours);

    const p_payable4 = 
    (contextData.p_Payrate4 * 52 * values.patrolHours) + 
    (contextData.p_Payrate4 * contextData.p_LeapHours) + 
    (contextData.p_Payrate4 * 1.5 * 9 * values.patrolStatHours);

    const e1_payable4 = 
    (contextData.e1_Payrate4 * 52 * values.extraHours1) + 
    (contextData.e1_Payrate4 * contextData.e1_LeapHours) + 
    (contextData.e1_Payrate4 * 1.5 * 9 * values.extraStatHours1);

    const e2_payable4 = 
    (contextData.e2_Payrate4 * 52 * values.extraHours2) + 
    (contextData.e2_Payrate4 * contextData.e2_LeapHours) + 
    (contextData.e2_Payrate4 * 1.5 * 9 * values.extraStatHours2);


    const total_payable4 = s_payable4 + c_payable4 + p_payable4 + e1_payable4 + e2_payable4;

    const s_payable5 = 
    (contextData.s_Payrate5 * 52 * values.supervisorHours) + 
    (contextData.s_Payrate5 * contextData.s_LeapHours) + 
    (contextData.s_Payrate5 * 1.5 * 9 * values.supervisorStatHours);

    const c_payable5 = 
    (contextData.c_Payrate5 * 52 * values.conciergeHours) + 
    (contextData.c_Payrate5 * contextData.c_LeapHours) + 
    (contextData.c_Payrate5 * 1.5 * 9 * values.conciergeStatHours);

    const p_payable5 = 
    (contextData.p_Payrate5 * 52 * values.patrolHours) + 
    (contextData.p_Payrate5 * contextData.p_LeapHours) + 
    (contextData.p_Payrate5 * 1.5 * 9 * values.patrolStatHours);

    const e1_payable5 = 
    (contextData.e1_Payrate5 * 52 * values.extraHours1) + 
    (contextData.e1_Payrate5 * contextData.e1_LeapHours) + 
    (contextData.e1_Payrate5 * 1.5 * 9 * values.extraStatHours1);

    const e2_payable5 = 
    (contextData.e2_Payrate5 * 52 * values.extraHours2) + 
    (contextData.e2_Payrate5 * contextData.e2_LeapHours) + 
    (contextData.e2_Payrate5 * 1.5 * 9 * values.extraStatHours2);


    const total_payable5 = s_payable5 + c_payable5 + p_payable5 + e1_payable5 + e2_payable5;

    const s_grossprofit1 = 
    (contextData.s_BillingRate1 * 52 * values.supervisorHours) + 
    (contextData.s_BillingRate1 * contextData.s_LeapHours) + 
    (contextData.s_BillingRate1 * 1.5 * 9 * values.supervisorStatHours) - s_payable1;

    const c_grossprofit1 = 
    (contextData.c_BillingRate1 * 52 * values.conciergeHours) + 
    (contextData.c_BillingRate1 * contextData.c_LeapHours) + 
    (contextData.c_BillingRate1 * 1.5 * 9 * values.conciergeStatHours) - c_payable1;

    const p_grossprofit1 = 
    (contextData.p_BillingRate1 * 52 * values.patrolHours) + 
    (contextData.p_BillingRate1 * contextData.p_LeapHours) + 
    (contextData.p_BillingRate1 * 1.5 * 9 * values.patrolStatHours) - p_payable1;

    const e1_grossprofit1 = 
    (contextData.e1_BillingRate1 * 52 * values.extraHours1) + 
    (contextData.e1_BillingRate1 * contextData.e1_LeapHours) + 
    (contextData.e1_BillingRate1 * 1.5 * 9 * values.extraStatHours1) - e1_payable1;

    const e2_grossprofit1 = 
    (contextData.e2_BillingRate1 * 52 * values.extraHours2) + 
    (contextData.e2_BillingRate1 * contextData.e2_LeapHours) + 
    (contextData.e2_BillingRate1 * 1.5 * 9 * values.extraStatHours2) - e2_payable1;

    const total_grossprofit1 = s_grossprofit1 + c_grossprofit1 + p_grossprofit1 + e1_grossprofit1 + + e2_grossprofit1;
    const profit_per1 = (total_grossprofit1 * 100 / total_payable1).toFixed(2);

    const s_grossprofit2 = 
    (contextData.s_BillingRate2 * 52 * values.supervisorHours) + 
    (contextData.s_BillingRate2 * contextData.s_LeapHours) + 
    (contextData.s_BillingRate2 * 1.5 * 9 * values.supervisorStatHours) - s_payable2;

    const c_grossprofit2 = 
    (contextData.c_BillingRate2 * 52 * values.conciergeHours) + 
    (contextData.c_BillingRate2 * contextData.c_LeapHours) + 
    (contextData.c_BillingRate2 * 1.5 * 9 * values.conciergeStatHours) - c_payable2;

    const p_grossprofit2 = 
    (contextData.p_BillingRate2 * 52 * values.patrolHours) + 
    (contextData.p_BillingRate2 * contextData.p_LeapHours) + 
    (contextData.p_BillingRate2 * 1.5 * 9 * values.patrolStatHours) - p_payable2;

    const e1_grossprofit2 = 
    (contextData.e1_BillingRate2 * 52 * values.extraHours1) + 
    (contextData.e1_BillingRate2 * contextData.e1_LeapHours) + 
    (contextData.e1_BillingRate2 * 1.5 * 9 * values.extraStatHours1) - e1_payable2;

    const e2_grossprofit2 = 
    (contextData.e2_BillingRate2 * 52 * values.extraHours2) + 
    (contextData.e2_BillingRate2 * contextData.e2_LeapHours) + 
    (contextData.e2_BillingRate2 * 1.5 * 9 * values.extraStatHours2) - e2_payable2;

    const total_grossprofit2 = s_grossprofit2 + c_grossprofit2 + p_grossprofit2 + e1_grossprofit2 + e2_grossprofit2;
    const profit_per2 = (total_grossprofit2 * 100 / total_payable2).toFixed(2);

    const s_grossprofit3 = 
    (contextData.s_BillingRate3 * 52 * values.supervisorHours) + 
    (contextData.s_BillingRate3 * contextData.s_LeapHours) + 
    (contextData.s_BillingRate3 * 1.5 * 9 * values.supervisorStatHours) - s_payable3;

    const c_grossprofit3 = 
    (contextData.c_BillingRate3 * 52 * values.conciergeHours) + 
    (contextData.c_BillingRate3 * contextData.c_LeapHours) + 
    (contextData.c_BillingRate3 * 1.5 * 9 * values.conciergeStatHours) - c_payable3;

    const p_grossprofit3 = 
    (contextData.p_BillingRate3 * 52 * values.patrolHours) + 
    (contextData.p_BillingRate3 * contextData.p_LeapHours) + 
    (contextData.p_BillingRate3 * 1.5 * 9 * values.patrolStatHours) - p_payable3;

    const e1_grossprofit3 = 
    (contextData.e1_BillingRate3 * 52 * values.extraHours1) + 
    (contextData.e1_BillingRate3 * contextData.e1_LeapHours) + 
    (contextData.e1_BillingRate3 * 1.5 * 9 * values.extraStatHours1) - e1_payable3;

    const e2_grossprofit3 = 
    (contextData.e2_BillingRate3 * 52 * values.extraHours2) + 
    (contextData.e2_BillingRate3 * contextData.e2_LeapHours) + 
    (contextData.e2_BillingRate3 * 1.5 * 9 * values.extraStatHours2) - e2_payable3;

    const total_grossprofit3 = s_grossprofit3 + c_grossprofit3 + p_grossprofit3 + e1_grossprofit3 + e2_grossprofit3;
    const profit_per3 = (total_grossprofit3 * 100 / total_payable3).toFixed(2);

    
    const s_grossprofit4 = 
    (contextData.s_BillingRate4 * 52 * values.supervisorHours) + 
    (contextData.s_BillingRate4 * contextData.s_LeapHours) + 
    (contextData.s_BillingRate4 * 1.5 * 9 * values.supervisorStatHours) - s_payable4;

    const c_grossprofit4 = 
    (contextData.c_BillingRate4 * 52 * values.conciergeHours) + 
    (contextData.c_BillingRate4 * contextData.c_LeapHours) + 
    (contextData.c_BillingRate4 * 1.5 * 9 * values.conciergeStatHours) - c_payable4;

    const p_grossprofit4 = 
    (contextData.p_BillingRate4 * 52 * values.patrolHours) + 
    (contextData.p_BillingRate4 * contextData.p_LeapHours) + 
    (contextData.p_BillingRate4 * 1.5 * 9 * values.patrolStatHours) - p_payable4;

    const e1_grossprofit4 = 
    (contextData.e1_BillingRate4 * 52 * values.extraHours1) + 
    (contextData.e1_BillingRate4 * contextData.e1_LeapHours) + 
    (contextData.e1_BillingRate4 * 1.5 * 9 * values.extraStatHours1) - e1_payable4;

    const e2_grossprofit4 = 
    (contextData.e2_BillingRate4 * 52 * values.extraHours2) + 
    (contextData.e2_BillingRate4 * contextData.e2_LeapHours) + 
    (contextData.e2_BillingRate4 * 1.5 * 9 * values.extraStatHours2) - e2_payable4;

    const total_grossprofit4 = s_grossprofit4 + c_grossprofit4 + p_grossprofit4 + e1_grossprofit4 + e2_grossprofit4;
    const profit_per4 = (total_grossprofit4 * 100 / total_payable4).toFixed(2);

    const s_grossprofit5 = 
    (contextData.s_BillingRate5 * 52 * values.supervisorHours) + 
    (contextData.s_BillingRate5 * contextData.s_LeapHours) + 
    (contextData.s_BillingRate5 * 1.5 * 9 * values.supervisorStatHours) - s_payable5;

    const c_grossprofit5 = 
    (contextData.c_BillingRate5 * 52 * values.conciergeHours) + 
    (contextData.c_BillingRate5 * contextData.c_LeapHours) + 
    (contextData.c_BillingRate5 * 1.5 * 9 * values.conciergeStatHours) - c_payable5;

    const p_grossprofit5 = 
    (contextData.p_BillingRate5 * 52 * values.patrolHours) + 
    (contextData.p_BillingRate5 * contextData.p_LeapHours) + 
    (contextData.p_BillingRate5 * 1.5 * 9 * values.patrolStatHours) - p_payable5;

    const e1_grossprofit5 = 
    (contextData.e1_BillingRate5 * 52 * values.extraHours1) + 
    (contextData.e1_BillingRate5 * contextData.e1_LeapHours) + 
    (contextData.e1_BillingRate5 * 1.5 * 9 * values.extraStatHours1) - e1_payable5;

    const e2_grossprofit5 = 
    (contextData.e2_BillingRate5 * 52 * values.extraHours2) + 
    (contextData.e2_BillingRate5 * contextData.e2_LeapHours) + 
    (contextData.e2_BillingRate5 * 1.5 * 9 * values.extraStatHours2) - e2_payable5;

    const total_grossprofit5 = s_grossprofit5 + c_grossprofit5 + p_grossprofit5 + e1_grossprofit5 + e2_grossprofit5;
    const profit_per5 = (total_grossprofit5 * 100 / total_payable5).toFixed(2);

    return {
      total1: Number(total1),
      hst1: Number(hst1),
      grandTotal1: Number(grandTotal1),
      monthlyTotal1: Number(monthlyTotal1),
      s_payable1: Number(s_payable1),
      c_payable1: Number(c_payable1),
      p_payable1: Number(p_payable1),
      e1_payable1: Number(e1_payable1),
      e2_payable1: Number(e2_payable1),
      total_payable1: Number(total_payable1),

      s_grossprofit1: Number(s_grossprofit1),
      c_grossprofit1: Number(c_grossprofit1),
      p_grossprofit1: Number(p_grossprofit1),
      e1_grossprofit1: Number(e1_grossprofit1),
      e2_grossprofit1: Number(e2_grossprofit1),
      total_grossprofit1: Number(total_grossprofit1),
      profit_per1: Number(profit_per1),

      total2: Number(total2),
      hst2: Number(hst2),
      grandTotal2: Number(grandTotal2),
      monthlyTotal2: Number(monthlyTotal2),
      s_payable2: Number(s_payable2),
      c_payable2: Number(c_payable2),
      p_payable2: Number(p_payable2),
      e1_payable2: Number(e1_payable2),
      e2_payable2: Number(e2_payable2),
      total_payable2: Number(total_payable2),

      s_grossprofit2: Number(s_grossprofit2),
      c_grossprofit2: Number(c_grossprofit2),
      p_grossprofit2: Number(p_grossprofit2),
      e1_grossprofit2: Number(e1_grossprofit2),
      e2_grossprofit2: Number(e2_grossprofit2),
      total_grossprofit2: Number(total_grossprofit2),
      profit_per2: Number(profit_per2),

      total3: Number(total3),
      hst3: Number(hst3),
      grandTotal3: Number(grandTotal3),
      monthlyTotal3: Number(monthlyTotal3),
      s_payable3: Number(s_payable3),
      c_payable3: Number(c_payable3),
      p_payable3: Number(p_payable3),
      e1_payable3: Number(e1_payable3),
      e2_payable3: Number(e2_payable3),
      total_payable3: Number(total_payable3),

      s_grossprofit3: Number(s_grossprofit3),
      c_grossprofit3: Number(c_grossprofit3),
      p_grossprofit3: Number(p_grossprofit3),
      e1_grossprofit3: Number(e1_grossprofit3),
      e2_grossprofit3: Number(e2_grossprofit3),
      total_grossprofit3: Number(total_grossprofit3),
      profit_per3: Number(profit_per3),

      total4: Number(total4),
      hst4: Number(hst4),
      grandTotal4: Number(grandTotal4),
      monthlyTotal4: Number(monthlyTotal4),
      s_payable4: Number(s_payable4),
      c_payable4: Number(c_payable4),
      p_payable4: Number(p_payable4),
      e1_payable4: Number(e1_payable4),
      e2_payable4: Number(e2_payable4),
      total_payable4: Number(total_payable4),

      s_grossprofit4: Number(s_grossprofit4),
      c_grossprofit4: Number(c_grossprofit4),
      p_grossprofit4: Number(p_grossprofit4),
      e1_grossprofit4: Number(e1_grossprofit4),
      e2_grossprofit4: Number(e2_grossprofit4),
      total_grossprofit4: Number(total_grossprofit4),
      profit_per4: Number(profit_per4),

      total5: Number(total5),
      hst5: Number(hst5),
      grandTotal5: Number(grandTotal5),
      monthlyTotal5: Number(monthlyTotal5),
      s_payable5: Number(s_payable5),
      c_payable5: Number(c_payable5),
      p_payable5: Number(p_payable5),
      e1_payable5: Number(e1_payable5),
      e2_payable5: Number(e2_payable5),
      total_payable5: Number(total_payable5),

      s_grossprofit5: Number(s_grossprofit5),
      c_grossprofit5: Number(c_grossprofit5),
      p_grossprofit5: Number(p_grossprofit5),
      e1_grossprofit5: Number(e1_grossprofit5),
      e2_grossprofit5: Number(e2_grossprofit5),
      total_grossprofit5: Number(total_grossprofit5),
      profit_per5: Number(profit_per5),
    };
  };

  const calculateContextData = useCallback(
    (values) => {
      const supervisorData = calculateSupervisorPayrate(values);
      const conciergeData = calculateConciergePayrate(values);
      const patrolData = calculatePatrolPayrate(values);
      const extraData1 = calculateExtra1Payrate(values);
      const extraData2 = calculateExtra2Payrate(values);

      // Combine the newly calculated data
      const newContextData = {
        ...supervisorData,
        ...conciergeData,
        ...patrolData,
        ...extraData1,
        ...extraData2,
      };

      // Pass the combined data to calculateTotal
      const totalData = calculateTotal(values, newContextData);

      // Return all the calculated data together
      return {
        ...newContextData,
        ...totalData,
      };
    },
    [] // No dependencies needed now
  );

  const updatePaymentData = (newValues) => {
    setPaymentData((prev) => {
      const updatedValues = { ...prev, ...newValues };
      const calculatedData = calculateContextData(updatedValues);
      setContextData(calculatedData);
      return updatedValues;
    });
  };

  return (
    <PaymentContext.Provider
      value={{ paymentData, updatePaymentData, contextData }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

// Custom hook to use the PaymentContext
export const usePaymentContext = () => useContext(PaymentContext);
