import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { formatCurrency } from "../utility/utility";

const FinalCard = ({ dataRef }) => {
  const { paymentData, contextData } = usePaymentContext();

  const {
    total1,
    hst1,
    grandTotal1,
    monthlyTotal1,

    s_billingStat1, 
    c_billingStat1, 
    p_billingStat1, 
    e1_billingStat1, 
    e2_billingStat1, 

    s_Payrate1,
    s_HoursYear,
    s_LeapHoursYear,
    s_BillingRate1,
    s_StatHoursYear,
    s_StatBillingRate1,
    c_Payrate1,
    c_HoursYear,
    c_LeapHoursYear,
    c_BillingRate1,
    c_StatHoursYear,
    c_StatBillingRate1,
    p_Payrate1,
    p_HoursYear,
    p_LeapHoursYear,
    p_BillingRate1,
    p_StatHoursYear,
    p_StatBillingRate1,
    e1_Payrate1,
    e1_HoursYear,
    e1_LeapHoursYear,
    e1_BillingRate1,
    e1_StatHoursYear,
    e1_StatBillingRate1,
    e2_Payrate1,
    e2_HoursYear,
    e2_LeapHoursYear,
    e2_BillingRate1,
    e2_StatHoursYear,
    e2_StatBillingRate1,
  } = contextData;

  const {
    supervisorTitle,
    conciergeTitle,
    patrolTitle,
    extraTitle1,
    extraTitle2,
    patrolProfit,
    extraProfit1,
    extraProfit2,
    hst,
    unionAmt,
    vacationAmt,
  } = paymentData;

  const s_RegLeapHours = s_HoursYear + s_LeapHoursYear || 0;;
  const s_RegLeapAmount = s_BillingRate1 * s_RegLeapHours || 0;
  // const s_StatAmount = s_StatBillingRate1 * s_StatHoursYear || 0;

  const c_RegLeapHours = c_HoursYear + c_LeapHoursYear || 0
  const c_RegLeapAmount = c_BillingRate1 * c_RegLeapHours || 0;
  // const c_StatAmount = c_StatBillingRate1 * c_StatHoursYear || 0;

  const p_RegLeapHours = p_HoursYear + p_LeapHoursYear || 0;
  const p_RegLeapAmount = p_BillingRate1 * p_RegLeapHours || 0;
  // const p_StatAmount = p_StatBillingRate1 * p_StatHoursYear || 0;

  const e1_RegLeapHours = e1_HoursYear + e1_LeapHoursYear || 0;
  const e1_RegLeapAmount = e1_BillingRate1 * e1_RegLeapHours || 0;
  // const e1_StatAmount = e1_StatBillingRate1 * e1_StatHoursYear || 0;

  const e2_RegLeapHours = e2_HoursYear + e2_LeapHoursYear || 0;
  const e2_RegLeapAmount = e2_BillingRate1 * e2_RegLeapHours || 0;
  // const e2_StatAmount = e2_StatBillingRate1 * e2_StatHoursYear || 0;

  // var preTaxAmount = s_RegLeapAmount +  s_StatAmount + c_RegLeapAmount + c_StatAmount + Number(unionAmt) + Number(vacationAmt);
  

  // if (patrolProfit)
  //   preTaxAmount = parseFloat(preTaxAmount) + p_RegLeapAmount + p_StatAmount;
  
  // if (extraProfit1)
  //   preTaxAmount = parseFloat(preTaxAmount) + e1_RegLeapAmount + e1_StatAmount;
  
  // if (extraProfit2)
  //   preTaxAmount = parseFloat(preTaxAmount) + e2_RegLeapAmount + e2_StatAmount;
  
  // preTaxAmount = Number(preTaxAmount);
  
  // console.log(s_billingStat1);
  // console.log(c_billingStat1);
  // console.log(p_billingStat1);
  // console.log(e1_billingStat1);
  // console.log(e2_billingStat1);


  // const hstAmount = (preTaxAmount * hst) / 100;
  // const grandTotal = Number(preTaxAmount) + Number(hstAmount);
  // const monthlyTotal = grandTotal / 12;

  return (
    <div className="container mx-auto bg-white p-4" ref={dataRef}>
      <div className="overflow-x-auto">
        <div className="m-2 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
          YEAR ONE
        </div>

        <table className="min-w-full bg-white">
          <tbody>
            <tr className="bg-blue-50">
              <th className="w-2/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                {supervisorTitle} @ {formatCurrency(s_Payrate1)}
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Yearly Hours
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Billing Rate
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Total
              </th>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Regular + Leap Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {s_RegLeapHours}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
               {formatCurrency(s_BillingRate1)}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(s_RegLeapAmount)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Statutory Holiday Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {s_StatHoursYear}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {formatCurrency(s_StatBillingRate1)}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(s_billingStat1)}
                {/* {formatCurrency(s_StatAmount)} */}
              </td>
            </tr>
            <tr className="bg-blue-50">
              <th className="w-2/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                {conciergeTitle} @ {formatCurrency(c_Payrate1)}
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Yearly Hours
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Billing Rate
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Total
              </th>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Regular + Leap Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {c_RegLeapHours}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
              {formatCurrency(c_BillingRate1)}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(c_RegLeapAmount)}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                Statutory Holiday Hours
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {c_StatHoursYear}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {formatCurrency(Number(c_StatBillingRate1))}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(c_billingStat1)}
                {/* {formatCurrency(c_StatAmount)} */}
              </td>
            </tr>
            {patrolProfit && (
              <>
                <tr className="bg-blue-50">
                  <th className="w-2/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    {patrolTitle} @ {formatCurrency(p_Payrate1)}
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Yearly Hours
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Billing Rate
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Total
                  </th>
                </tr>
                <tr>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    Regular + Leap Hours
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {p_RegLeapHours}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {formatCurrency(p_BillingRate1)}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                    {formatCurrency(p_RegLeapAmount)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    Statutory Holiday Hours
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {p_StatHoursYear}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {formatCurrency(Number(p_StatBillingRate1))}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(p_billingStat1)}
                {/* {formatCurrency(p_StatAmount)} */}
                  </td>
                </tr>
              </>
            )}
            {extraProfit1 && (
              <>
                <tr className="bg-blue-50">
                  <th className="w-2/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    {extraTitle1} @ {formatCurrency(e1_Payrate1)}
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Yearly Hours
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Billing Rate
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Total
                  </th>
                </tr>
                <tr>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    Regular + Leap Hours
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {e1_RegLeapHours}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {formatCurrency(e1_BillingRate1)}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                    {formatCurrency(e1_RegLeapAmount)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    Statutory Holiday Hours
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {e1_StatHoursYear}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {formatCurrency(Number(e1_StatBillingRate1))}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(e1_billingStat1)}
                {/* {formatCurrency(e1_StatAmount)} */}
                  </td>
                </tr>
              </>
            )}
            {extraProfit2 && (
              <>
                <tr className="bg-blue-50">
                  <th className="w-2/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    {extraTitle2} @ {formatCurrency(e2_Payrate1)}
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Yearly Hours
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Billing Rate
                  </th>
                  <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                    Total
                  </th>
                </tr>
                <tr>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    Regular + Leap Hours
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {e2_RegLeapHours}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {formatCurrency(e2_BillingRate1)}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                    {formatCurrency(e2_RegLeapAmount)}
                  </td>
                </tr>
                <tr>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    Statutory Holiday Hours
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {e2_StatHoursYear}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                    {formatCurrency(Number(e2_StatBillingRate1))}
                  </td>
                  <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {formatCurrency(e2_billingStat1)}
                {/* {formatCurrency(e2_StatAmount)} */}
                  </td>
                </tr>
              </>
            )}
            <tr>
              <th colSpan={2}></th>
              <th className="bg-blue-50 w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Total pre-Tax
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-right text-sm font-semibold">
                {formatCurrency(total1)}
              </th>
            </tr>
            <tr>
              <th colSpan={2}></th>
              <th className="bg-blue-50 w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                HST @{hst}%
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-right text-sm font-semibold">
                {formatCurrency(hst1)}
              </th>
            </tr>
            <tr>
              <th colSpan={2}></th>
              <th className="bg-blue-50 w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Grand Total
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-right text-sm font-semibold">
                {formatCurrency(grandTotal1)}
              </th>
            </tr>
            <tr>
              <th colSpan={2}></th>
              <th className="bg-blue-50 w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Monthly Invoice
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-right text-sm font-semibold">
                {formatCurrency(monthlyTotal1)}
              </th>
            </tr>
          </tbody>
        </table>
        {unionAmt && (
          <p className="m-2 text-gray-800 text-sm font-semibold">
          {formatCurrency(unionAmt)} Additional yearly amount added to total pre-tax due to
          Union
        </p>
        )}
        {vacationAmt && (
        <p className="m-2 text-gray-800 text-sm font-semibold">
          {formatCurrency(vacationAmt)} Additional yearly amount added to total pre-tax due to
          Vacation
        </p>
        )}
      </div>
    </div>
  );
};

export default FinalCard;
