import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deleteProposalRequest,
  fetchProposalRequest,
  setSortConfig,
} from "../redux/actions/proposalActions";
import { getDateTime } from "../utility/utility";
import {
  DocumentMagnifyingGlassIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

const ProposalList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.proposal.data);
  const loading = useSelector((state) => state.proposal.loading);
  const error = useSelector((state) => state.proposal.error);
  const sortConfig = useSelector((state) => state.proposal.sortConfig);

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  // const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(fetchProposalRequest());
  }, [dispatch]);

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  // Sorting logic
  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    // setSortConfig({ key, direction });
    const newSortConfig = { key, direction };
    setSortConfig(newSortConfig);
    dispatch(setSortConfig(newSortConfig));
  };

  const handleView = (id) => {
    navigate(`/displayproposal/${id}`);
  };

  const handleEdit = (id) => {
    // console.log(id);

    navigate(`/editproposal/${id}`);
  };

  const handleDelete = (id) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this proposal?"
    );
    if (confirmed) {
      const proposalobj = {
        id: id,
      };
      dispatch(deleteProposalRequest(proposalobj));
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const filteredData = sortedData.filter(
    (record) =>
      record.sitename.toLowerCase().includes(searchQuery.toLowerCase()) ||
      record.corpno.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <div className="container mx-auto p-4">
        <p>Error: You are not authorized to access this resource.</p>
      </div>
    );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-semibold mb-6 text-center text-gray-800">
        Proposals List
      </h1>

      <div className="mb-6 flex justify-center">
        <input
          type="text"
          placeholder="Search by Site Name or Corp No"
          className="p-3 w-full md:w-1/2 lg:w-1/3 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      <div className="shadow overflow-hidden border border-gray-300 sm:rounded-lg">
        <table className="min-w-full bg-white border-collapse table-auto">
          <thead className="bg-blue-50">
            <tr>
              <th
                className="w-1/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider cursor-pointer"
                onClick={() => requestSort("id")}
              >
                ID
                {sortConfig.key === "id" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th
                className="w-2/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider cursor-pointer"
                onClick={() => requestSort("corpno")}
              >
                Corporation No
                {sortConfig.key === "corpno" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th
                className="w-3/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider cursor-pointer"
                onClick={() => requestSort("sitename")}
              >
                Site Name
                {sortConfig.key === "sitename" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th className="w-2/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                Phone
              </th>
              <th className="w-2/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                Email
              </th>
              <th className="w-3/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                Created By
              </th>
              <th
                className="w-2/12 px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider cursor-pointer"
                onClick={() => requestSort("date_Created")}
              >
                Proposal Date
                {sortConfig.key === "date_Created" &&
                  (sortConfig.direction === "ascending" ? "↑" : "↓")}
              </th>
              <th className="px-2 sm:px-4 py-2 border text-left text-xs font-medium text-gray-800 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentRecords.map((record) => (
              <tr key={record.id} className="hover:bg-gray-50">
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {record.id}
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {record.corpno}
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {record.sitename}
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {record.phone}
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {record.email}
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {record.createdby_Empname} ({record.createdby_Empid})<br />
                  {record.createdby_Emptype}
                  <br />
                  {record.createdby_Phone}
                  <br />
                  {record.createdby_Email}
                  <br />
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs text-gray-900 border">
                  {getDateTime(record.date_Created)}
                </td>
                <td className="px-2 sm:px-4 py-2 whitespace-nowrap text-xs font-medium border">
                  <button
                    onClick={() => handleView(record.id)}
                    className="px-1 py-1"
                  >
                    <DocumentMagnifyingGlassIcon
                      className={`h-6 w-6 text-blue-400`}
                      title="Detail"
                    />
                  </button>
                  <button
                    onClick={() => handleEdit(record.id)}
                    className="px-1 py-1"
                  >
                    <PencilSquareIcon
                      className={`h-6 w-6 text-blue-400`}
                      title="Edit"
                    />
                  </button>
                  <button
                    onClick={() => handleDelete(record.id)}
                    className="px-1 py-1"
                  >
                    <TrashIcon
                      className={`h-6 w-6 text-blue-400`}
                      title="Delete"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center mt-6">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={`py-2 px-4 mx-1 border rounded-lg ${
              currentPage === index + 1
                ? "bg-blue-500 text-white"
                : "bg-gray-200"
            }`}
            onClick={() => paginate(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ProposalList;
