import React from "react";
import PaymentCardEdit from "./PaymentCardEdit";
const ProposalPlanEdit = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center space-y-4">
      <div className="w-full">
        <PaymentCardEdit />
      </div>
    </div>
  );
};
export default ProposalPlanEdit;
