import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import config from "../config";

const AxiosInterceptor = () => {
  axios.interceptors.request.use(
    (req) => {
      // console.log("----Request Interceptor ----");
      // console.log(`${req.method} ${req.url} ${req.headers}`);

      const access_token = localStorage.getItem("access_token");
      if (access_token) {
        verifyToken();
        const headers = {
          // "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        };
        req.headers = headers;
      }
      return req;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(undefined, (err) => {
    const error = err.response;
    const originalRequest = error.config;
    // console.log("----Response Interceptor ----");
    // console.log(originalRequest);
    // console.log(error.config.__isRetryRequest);
    if (
      error.status === 401 &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      const refreshToken = localStorage.getItem("refresh_token");
      const empid = localStorage.getItem("empid");
      //   console.log(refreshToken);
      //   console.log(empid);
      return axios
        .post(config.APIURL.emploginapi.refresh, {
          refreshtoken: refreshToken,
          empid: parseInt(empid),
        })
        .then((res) => {
          //   console.log(res.data);
          const data = res.data;
          if (data.errorlogin === "0") {
            localStorage.setItem("access_token", res.data.accessToken);
            localStorage.setItem("refresh_token", res.data.refreshToken);
            const access_token = localStorage.getItem("access_token");
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + access_token;
            return axios(originalRequest);
          } else {
            console.log("Refresh Error");
            Cookies.remove("loggedIn");
            localStorage.clear();
            window.location.href = "/login";
            return Promise.reject(error);
          }
        });
    }
  });

  const verifyToken = () => {
    const empid = localStorage.getItem("empid");
    const companyid = localStorage.getItem("companyid");
    const access_token = localStorage.getItem("access_token");
    const decode = jwtDecode(access_token);

    if (empid !== decode.empid) {
      Cookies.remove("loggedIn");
      localStorage.clear();
      window.location.href = "/login";
    }
    if (companyid !== decode.companyid) {
      Cookies.remove("loggedIn");
      localStorage.clear();
      window.location.href = "/login";
    }

    // console.log("Token Verified");
  };
};

export default AxiosInterceptor;
