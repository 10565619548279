import { formatCurrency } from "./utility";

const getYearData = (emptype, year, paymentData, contextData) => {
  var emptitle, profit, hours, leapHours, statHours;
  var payrate,
    billingRate,
    statBillingRate,
    billingYear,
    billingLeap,
    billingStat,
    billingTotal;
  if (emptype === "Site Supervisor") {
    emptitle = paymentData.supervisorTitle;
    profit = paymentData.supervisorProfit;
    hours = paymentData.supervisorHours;
    leapHours = contextData.s_LeapHours;
    statHours = paymentData.supervisorStatHours;
    switch (Number(year)) {
      case 1:
        payrate = formatCurrency(contextData.s_Payrate1);
        billingRate = formatCurrency(contextData.s_BillingRate1);
        statBillingRate = formatCurrency(contextData.s_StatBillingRate1);
        billingYear = formatCurrency(contextData.s_billingYear1);
        billingLeap = formatCurrency(contextData.s_billingLeap1);
        billingStat = formatCurrency(contextData.s_billingStat1);
        billingTotal = formatCurrency(contextData.s_billingTotal1);
        break;
      case 2:
        payrate = formatCurrency(contextData.s_Payrate2);
        billingRate = formatCurrency(contextData.s_BillingRate2);
        statBillingRate = formatCurrency(contextData.s_StatBillingRate2);
        billingYear = formatCurrency(contextData.s_billingYear2);
        billingLeap = formatCurrency(contextData.s_billingLeap2);
        billingStat = formatCurrency(contextData.s_billingStat2);
        billingTotal = formatCurrency(contextData.s_billingTotal2);
        break;
      case 3:
        payrate = formatCurrency(contextData.s_Payrate3);
        billingRate = formatCurrency(contextData.s_BillingRate3);
        statBillingRate = formatCurrency(contextData.s_StatBillingRate3);
        billingYear = formatCurrency(contextData.s_billingYear3);
        billingLeap = formatCurrency(contextData.s_billingLeap3);
        billingStat = formatCurrency(contextData.s_billingStat3);
        billingTotal = formatCurrency(contextData.s_billingTotal3);
        break;
      case 4:
        payrate = formatCurrency(contextData.s_Payrate4);
        billingRate = formatCurrency(contextData.s_BillingRate4);
        statBillingRate = formatCurrency(contextData.s_StatBillingRate4);
        billingYear = formatCurrency(contextData.s_billingYear4);
        billingLeap = formatCurrency(contextData.s_billingLeap4);
        billingStat = formatCurrency(contextData.s_billingStat4);
        billingTotal = formatCurrency(contextData.s_billingTotal4);
        break;
      case 5:
        payrate = formatCurrency(contextData.s_Payrate5);
        billingRate = formatCurrency(contextData.s_BillingRate5);
        statBillingRate = formatCurrency(contextData.s_StatBillingRate5);
        billingYear = formatCurrency(contextData.s_billingYear5);
        billingLeap = formatCurrency(contextData.s_billingLeap5);
        billingStat = formatCurrency(contextData.s_billingStat5);
        billingTotal = formatCurrency(contextData.s_billingTotal5);
        break;
      default:
    }
  } else if (emptype === "Concierge") {
    emptitle = paymentData.conciergeTitle;
    profit = paymentData.conciergeProfit;
    hours = paymentData.conciergeHours;
    leapHours = contextData.c_LeapHours;
    statHours = paymentData.conciergeStatHours;
    switch (Number(year)) {
      case 1:
        payrate = formatCurrency(contextData.c_Payrate1);
        billingRate = formatCurrency(contextData.c_BillingRate1);
        statBillingRate = formatCurrency(contextData.c_StatBillingRate1);
        billingYear = formatCurrency(contextData.c_billingYear1);
        billingLeap = formatCurrency(contextData.c_billingLeap1);
        billingStat = formatCurrency(contextData.c_billingStat1);
        billingTotal = formatCurrency(contextData.c_billingTotal1);
        break;
      case 2:
        payrate = formatCurrency(contextData.c_Payrate2);
        billingRate = formatCurrency(contextData.c_BillingRate2);
        statBillingRate = formatCurrency(contextData.c_StatBillingRate2);
        billingYear = formatCurrency(contextData.c_billingYear2);
        billingLeap = formatCurrency(contextData.c_billingLeap2);
        billingStat = formatCurrency(contextData.c_billingStat2);
        billingTotal = formatCurrency(contextData.c_billingTotal2);
        break;
      case 3:
        payrate = formatCurrency(contextData.c_Payrate3);
        billingRate = formatCurrency(contextData.c_BillingRate3);
        statBillingRate = formatCurrency(contextData.c_StatBillingRate3);
        billingYear = formatCurrency(contextData.c_billingYear3);
        billingLeap = formatCurrency(contextData.c_billingLeap3);
        billingStat = formatCurrency(contextData.c_billingStat3);
        billingTotal = formatCurrency(contextData.c_billingTotal3);
        break;
      case 4:
        payrate = formatCurrency(contextData.c_Payrate4);
        billingRate = formatCurrency(contextData.c_BillingRate4);
        statBillingRate = formatCurrency(contextData.c_StatBillingRate4);
        billingYear = formatCurrency(contextData.c_billingYear4);
        billingLeap = formatCurrency(contextData.c_billingLeap4);
        billingStat = formatCurrency(contextData.c_billingStat4);
        billingTotal = formatCurrency(contextData.c_billingTotal4);
        break;
      case 5:
        payrate = formatCurrency(contextData.c_Payrate5);
        billingRate = formatCurrency(contextData.c_BillingRate5);
        statBillingRate = formatCurrency(contextData.c_StatBillingRate5);
        billingYear = formatCurrency(contextData.c_billingYear5);
        billingLeap = formatCurrency(contextData.c_billingLeap5);
        billingStat = formatCurrency(contextData.c_billingStat5);
        billingTotal = formatCurrency(contextData.c_billingTotal5);
        break;
      default:
    }
  } else if (emptype === "Patrol") {
    emptitle = paymentData.patrolTitle;
    profit = paymentData.patrolProfit;
    hours = paymentData.patrolHours;
    leapHours = contextData.p_LeapHours;
    statHours = paymentData.patrolStatHours;
    switch (Number(year)) {
      case 1:
        payrate = formatCurrency(contextData.p_Payrate1);
        billingRate = formatCurrency(contextData.p_BillingRate1);
        statBillingRate = formatCurrency(contextData.p_StatBillingRate1);
        billingYear = formatCurrency(contextData.p_billingYear1);
        billingLeap = formatCurrency(contextData.p_billingLeap1);
        billingStat = formatCurrency(contextData.p_billingStat1);
        billingTotal = formatCurrency(contextData.p_billingTotal1);
        break;
      case 2:
        payrate = formatCurrency(contextData.p_Payrate2);
        billingRate = formatCurrency(contextData.p_BillingRate2);
        statBillingRate = formatCurrency(contextData.p_StatBillingRate2);
        billingYear = formatCurrency(contextData.p_billingYear2);
        billingLeap = formatCurrency(contextData.p_billingLeap2);
        billingStat = formatCurrency(contextData.p_billingStat2);
        billingTotal = formatCurrency(contextData.p_billingTotal2);
        break;
      case 3:
        payrate = formatCurrency(contextData.p_Payrate3);
        billingRate = formatCurrency(contextData.p_BillingRate3);
        statBillingRate = formatCurrency(contextData.p_StatBillingRate3);
        billingYear = formatCurrency(contextData.p_billingYear3);
        billingLeap = formatCurrency(contextData.p_billingLeap3);
        billingStat = formatCurrency(contextData.p_billingStat3);
        billingTotal = formatCurrency(contextData.p_billingTotal3);
        break;
      case 4:
        payrate = formatCurrency(contextData.p_Payrate4);
        billingRate = formatCurrency(contextData.p_BillingRate4);
        statBillingRate = formatCurrency(contextData.p_StatBillingRate4);
        billingYear = formatCurrency(contextData.p_billingYear4);
        billingLeap = formatCurrency(contextData.p_billingLeap4);
        billingStat = formatCurrency(contextData.p_billingStat4);
        billingTotal = formatCurrency(contextData.p_billingTotal4);
        break;
      case 5:
        payrate = formatCurrency(contextData.p_Payrate5);
        billingRate = formatCurrency(contextData.p_BillingRate5);
        statBillingRate = formatCurrency(contextData.p_StatBillingRate5);
        billingYear = formatCurrency(contextData.p_billingYear5);
        billingLeap = formatCurrency(contextData.p_billingLeap5);
        billingStat = formatCurrency(contextData.p_billingStat5);
        billingTotal = formatCurrency(contextData.p_billingTotal5);
        break;
      default:
    }
  } else if (emptype === "Extra1") {
    emptitle = paymentData.extraTitle1;
    profit = paymentData.extraProfit1;
    hours = paymentData.extraHours1;
    leapHours = contextData.e1_LeapHours;
    statHours = paymentData.extraStatHours1;
    switch (Number(year)) {
      case 1:
        payrate = formatCurrency(contextData.e1_Payrate1);
        billingRate = formatCurrency(contextData.e1_BillingRate1);
        statBillingRate = formatCurrency(contextData.e1_StatBillingRate1);
        billingYear = formatCurrency(contextData.e1_billingYear1);
        billingLeap = formatCurrency(contextData.e1_billingLeap1);
        billingStat = formatCurrency(contextData.e1_billingStat1);
        billingTotal = formatCurrency(contextData.e1_billingTotal1);
        break;
      case 2:
        payrate = formatCurrency(contextData.e1_Payrate2);
        billingRate = formatCurrency(contextData.e1_BillingRate2);
        statBillingRate = formatCurrency(contextData.e1_StatBillingRate2);
        billingYear = formatCurrency(contextData.e1_billingYear2);
        billingLeap = formatCurrency(contextData.e1_billingLeap2);
        billingStat = formatCurrency(contextData.e1_billingStat2);
        billingTotal = formatCurrency(contextData.e1_billingTotal2);
        break;
      case 3:
        payrate = formatCurrency(contextData.e1_Payrate3);
        billingRate = formatCurrency(contextData.e1_BillingRate3);
        statBillingRate = formatCurrency(contextData.e1_StatBillingRate3);
        billingYear = formatCurrency(contextData.e1_billingYear3);
        billingLeap = formatCurrency(contextData.e1_billingLeap3);
        billingStat = formatCurrency(contextData.e1_billingStat3);
        billingTotal = formatCurrency(contextData.e1_billingTotal3);
        break;
      case 4:
        payrate = formatCurrency(contextData.e1_Payrate4);
        billingRate = formatCurrency(contextData.e1_BillingRate4);
        statBillingRate = formatCurrency(contextData.e1_StatBillingRate4);
        billingYear = formatCurrency(contextData.e1_billingYear4);
        billingLeap = formatCurrency(contextData.e1_billingLeap4);
        billingStat = formatCurrency(contextData.e1_billingStat4);
        billingTotal = formatCurrency(contextData.e1_billingTotal4);
        break;
      case 5:
        payrate = formatCurrency(contextData.e1_Payrate5);
        billingRate = formatCurrency(contextData.e1_BillingRate5);
        statBillingRate = formatCurrency(contextData.e1_StatBillingRate5);
        billingYear = formatCurrency(contextData.e1_billingYear5);
        billingLeap = formatCurrency(contextData.e1_billingLeap5);
        billingStat = formatCurrency(contextData.e1_billingStat5);
        billingTotal = formatCurrency(contextData.e1_billingTotal5);
        break;
      default:
    }
  } else if (emptype === "Extra2") {
    emptitle = paymentData.extraTitle2;
    profit = paymentData.extraProfit2;
    hours = paymentData.extraHours2;
    leapHours = contextData.e2_LeapHours;
    statHours = paymentData.extraStatHours2;
    switch (Number(year)) {
      case 1:
        payrate = formatCurrency(contextData.e2_Payrate1);
        billingRate = formatCurrency(contextData.e2_BillingRate1);
        statBillingRate = formatCurrency(contextData.e2_StatBillingRate1);
        billingYear = formatCurrency(contextData.e2_billingYear1);
        billingLeap = formatCurrency(contextData.e2_billingLeap1);
        billingStat = formatCurrency(contextData.e2_billingStat1);
        billingTotal = formatCurrency(contextData.e2_billingTotal1);
        break;
      case 2:
        payrate = formatCurrency(contextData.e2_Payrate2);
        billingRate = formatCurrency(contextData.e2_BillingRate2);
        statBillingRate = formatCurrency(contextData.e2_StatBillingRate2);
        billingYear = formatCurrency(contextData.e2_billingYear2);
        billingLeap = formatCurrency(contextData.e2_billingLeap2);
        billingStat = formatCurrency(contextData.e2_billingStat2);
        billingTotal = formatCurrency(contextData.e2_billingTotal2);
        break;
      case 3:
        payrate = formatCurrency(contextData.e2_Payrate3);
        billingRate = formatCurrency(contextData.e2_BillingRate3);
        statBillingRate = formatCurrency(contextData.e2_StatBillingRate3);
        billingYear = formatCurrency(contextData.e2_billingYear3);
        billingLeap = formatCurrency(contextData.e2_billingLeap3);
        billingStat = formatCurrency(contextData.e2_billingStat3);
        billingTotal = formatCurrency(contextData.e2_billingTotal3);
        break;
      case 4:
        payrate = formatCurrency(contextData.e2_Payrate4);
        billingRate = formatCurrency(contextData.e2_BillingRate4);
        statBillingRate = formatCurrency(contextData.e2_StatBillingRate4);
        billingYear = formatCurrency(contextData.e2_billingYear4);
        billingLeap = formatCurrency(contextData.e2_billingLeap4);
        billingStat = formatCurrency(contextData.e2_billingStat4);
        billingTotal = formatCurrency(contextData.e2_billingTotal4);
        break;
      case 5:
        payrate = formatCurrency(contextData.e2_Payrate5);
        billingRate = formatCurrency(contextData.e2_BillingRate5);
        statBillingRate = formatCurrency(contextData.e2_StatBillingRate5);
        billingYear = formatCurrency(contextData.e2_billingYear5);
        billingLeap = formatCurrency(contextData.e2_billingLeap5);
        billingStat = formatCurrency(contextData.e2_billingStat5);
        billingTotal = formatCurrency(contextData.e2_billingTotal5);
        break;
      default:
    }
  }
  return {
    emptitle,
    year,
    profit,
    payrate,
    billingRate,
    statBillingRate,
    hours,
    leapHours,
    statHours,
    billingYear,
    billingLeap,
    billingStat,
    billingTotal,
  };
};

const getExtraData = (emptype, contextData) => {
  var hoursYear, leapHoursYear, statHoursYear;
  if (emptype === "Site Supervisor") {
    hoursYear = contextData.s_HoursYear || 0;
    leapHoursYear = contextData.s_LeapHoursYear || 0;
    statHoursYear = contextData.s_StatHoursYear || 0;
  } else if (emptype === "Concierge") {
    hoursYear = contextData.c_HoursYear || 0;
    leapHoursYear = contextData.c_LeapHoursYear || 0;
    statHoursYear = contextData.c_StatHoursYear || 0;
  } else if (emptype === "Patrol") {
    hoursYear = contextData.p_HoursYear || 0;
    leapHoursYear = contextData.p_LeapHoursYear || 0;
    statHoursYear = contextData.p_StatHoursYear || 0;
  } else if (emptype === "Extra1") {
    hoursYear = contextData.e1_HoursYear || 0;
    leapHoursYear = contextData.e1_LeapHoursYear || 0;
    statHoursYear = contextData.e1_StatHoursYear || 0;
  } else if (emptype === "Extra2") {
    hoursYear = contextData.e2_HoursYear || 0;
    leapHoursYear = contextData.e2_LeapHoursYear || 0;
    statHoursYear = contextData.e2_StatHoursYear || 0;
  }

  return {
    hoursYear,
    leapHoursYear,
    statHoursYear,
  };
};

const getTotalData = (year, paymentData, contextData) => {
  var total,
    hst,
    grandTotal,
    monthlyTotal,
    s_payrate,
    c_payrate,
    p_payrate,
    e1_payrate,
    e2_payrate,

    s_payable,
    c_payable,
    p_payable,
    e1_payable,
    e2_payable,

    total_payable,
    
    s_grossprofit,
    c_grossprofit,
    p_grossprofit,
    e1_grossprofit,
    e2_grossprofit,

    total_grossprofit,
    profit_per;
    // console.log(contextData);
    
  switch (Number(year)) {
    case 1:
      total = formatCurrency(contextData.total1);
      hst = formatCurrency(contextData.hst1);
      grandTotal = formatCurrency(contextData.grandTotal1);
      monthlyTotal = formatCurrency(contextData.monthlyTotal1);
      s_payrate = formatCurrency(contextData.s_Payrate1);
      c_payrate = formatCurrency(contextData.c_Payrate1);
      p_payrate = formatCurrency(contextData.p_Payrate1);
      e1_payrate = formatCurrency(contextData.e1_Payrate1);
      e2_payrate = formatCurrency(contextData.e2_Payrate1);

      s_payable = formatCurrency(contextData.s_payable1);
      c_payable = formatCurrency(contextData.c_payable1);
      p_payable = formatCurrency(contextData.p_payable1);
      e1_payable = formatCurrency(contextData.e1_payable1);
      e2_payable = formatCurrency(contextData.e2_payable1);
      total_payable = formatCurrency(contextData.total_payable1);

      s_grossprofit = formatCurrency(contextData.s_grossprofit1);
      c_grossprofit = formatCurrency(contextData.c_grossprofit1);
      p_grossprofit = formatCurrency(contextData.p_grossprofit1);
      e1_grossprofit = formatCurrency(contextData.e1_grossprofit1);
      e2_grossprofit = formatCurrency(contextData.e2_grossprofit1);
      total_grossprofit = formatCurrency(contextData.total_grossprofit1);
      profit_per = contextData.profit_per1 || 0;

      break;
    case 2:
      total = formatCurrency(contextData.total2);
      hst = formatCurrency(contextData.hst2);
      grandTotal = formatCurrency(contextData.grandTotal2);
      monthlyTotal = formatCurrency(contextData.monthlyTotal2);
      s_payrate = formatCurrency(contextData.s_Payrate2);
      c_payrate = formatCurrency(contextData.c_Payrate2);
      p_payrate = formatCurrency(contextData.p_Payrate2);
      e1_payrate = formatCurrency(contextData.e1_Payrate2);
      e2_payrate = formatCurrency(contextData.e2_Payrate2);

      s_payable = formatCurrency(contextData.s_payable2);
      c_payable = formatCurrency(contextData.c_payable2);
      p_payable = formatCurrency(contextData.p_payable2);
      e1_payable = formatCurrency(contextData.e1_payable2);
      e2_payable = formatCurrency(contextData.e2_payable2);
      total_payable = formatCurrency(contextData.total_payable2);

      s_grossprofit = formatCurrency(contextData.s_grossprofit2);
      c_grossprofit = formatCurrency(contextData.c_grossprofit2);
      p_grossprofit = formatCurrency(contextData.p_grossprofit2);
      e1_grossprofit = formatCurrency(contextData.e1_grossprofit2);
      e2_grossprofit = formatCurrency(contextData.e2_grossprofit2);
      total_grossprofit = formatCurrency(contextData.total_grossprofit2);
      profit_per = contextData.profit_per2 || 0;
      break;
    case 3:
      total = formatCurrency(contextData.total3);
      hst = formatCurrency(contextData.hst3);
      grandTotal = formatCurrency(contextData.grandTotal3);
      monthlyTotal = formatCurrency(contextData.monthlyTotal3);
      s_payrate = formatCurrency(contextData.s_Payrate3);
      c_payrate = formatCurrency(contextData.c_Payrate3);
      p_payrate = formatCurrency(contextData.p_Payrate3);
      e1_payrate = formatCurrency(contextData.e1_Payrate3);
      e2_payrate = formatCurrency(contextData.e2_Payrate3);

      s_payable = formatCurrency(contextData.s_payable3);
      c_payable = formatCurrency(contextData.c_payable3);
      p_payable = formatCurrency(contextData.p_payable3);
      e1_payable = formatCurrency(contextData.e1_payable3);
      e2_payable = formatCurrency(contextData.e2_payable3);
      total_payable = formatCurrency(contextData.total_payable3);

      s_grossprofit = formatCurrency(contextData.s_grossprofit3);
      c_grossprofit = formatCurrency(contextData.c_grossprofit3);
      p_grossprofit = formatCurrency(contextData.p_grossprofit3);
      e1_grossprofit = formatCurrency(contextData.e1_grossprofit3);
      e2_grossprofit = formatCurrency(contextData.e2_grossprofit3);
      total_grossprofit = formatCurrency(contextData.total_grossprofit3);
      profit_per = contextData.profit_per3 || 0;
      break;
    case 4:
      total = formatCurrency(contextData.total4);
      hst = formatCurrency(contextData.hst4);
      grandTotal = formatCurrency(contextData.grandTotal4);
      monthlyTotal = formatCurrency(contextData.monthlyTotal4);
      s_payrate = formatCurrency(contextData.s_Payrate4);
      c_payrate = formatCurrency(contextData.c_Payrate4);
      p_payrate = formatCurrency(contextData.p_Payrate4);
      e1_payrate = formatCurrency(contextData.e1_Payrate4);
      e2_payrate = formatCurrency(contextData.e2_Payrate4);

      s_payable = formatCurrency(contextData.s_payable4);
      c_payable = formatCurrency(contextData.c_payable4);
      p_payable = formatCurrency(contextData.p_payable4);
      e1_payable = formatCurrency(contextData.e1_payable4);
      e2_payable = formatCurrency(contextData.e2_payable4);
      total_payable = formatCurrency(contextData.total_payable4);

      s_grossprofit = formatCurrency(contextData.s_grossprofit4);
      c_grossprofit = formatCurrency(contextData.c_grossprofit4);
      p_grossprofit = formatCurrency(contextData.p_grossprofit4);
      e1_grossprofit = formatCurrency(contextData.e1_grossprofit4);
      e2_grossprofit = formatCurrency(contextData.e2_grossprofit4);
      total_grossprofit = formatCurrency(contextData.total_grossprofit4);
      profit_per = contextData.profit_per4 || 0;
      break;
    case 5:
      total = formatCurrency(contextData.total5);
      hst = formatCurrency(contextData.hst5);
      grandTotal = formatCurrency(contextData.grandTotal5);
      monthlyTotal = formatCurrency(contextData.monthlyTotal5);
      s_payrate = formatCurrency(contextData.s_Payrate5);
      c_payrate = formatCurrency(contextData.c_Payrate5);
      p_payrate = formatCurrency(contextData.p_Payrate5);
      e1_payrate = formatCurrency(contextData.e1_Payrate5);
      e2_payrate = formatCurrency(contextData.e2_Payrate5);

      s_payable = formatCurrency(contextData.s_payable5);
      c_payable = formatCurrency(contextData.c_payable5);
      p_payable = formatCurrency(contextData.p_payable5);
      e1_payable = formatCurrency(contextData.e1_payable5);
      e2_payable = formatCurrency(contextData.e2_payable5);
      total_payable = formatCurrency(contextData.total_payable5);

      s_grossprofit = formatCurrency(contextData.s_grossprofit5);
      c_grossprofit = formatCurrency(contextData.c_grossprofit5);
      p_grossprofit = formatCurrency(contextData.p_grossprofit5);
      e1_grossprofit = formatCurrency(contextData.e1_grossprofit5);
      e2_grossprofit = formatCurrency(contextData.e2_grossprofit5);
      total_grossprofit = formatCurrency(contextData.total_grossprofit5);
      profit_per = contextData.profit_per5 || 0;
      break;
    default:
  }
  return {
    total,
    hst,
    grandTotal,
    monthlyTotal,
    s_payrate,
    c_payrate,
    p_payrate,
    e1_payrate,
    e2_payrate,

    s_payable,
    c_payable,
    p_payable,
    e1_payable,
    e2_payable,

    total_payable,
    s_grossprofit,
    c_grossprofit,
    p_grossprofit,
    e1_grossprofit,
    e2_grossprofit,

    total_grossprofit,
    profit_per,
  };
};

const getYearPercent = (
  year,
  year2Percent,
  year3Percent,
  year4Percent,
  year5Percent
) => {
  switch (Number(year)) {
    case 2:
      return year2Percent;
    case 3:
      return year3Percent;
    case 4:
      return year4Percent;
    case 5:
      return year5Percent;
    default:
      return 0;
  }
};

const getYearTotal = (
  year,
  totalYear1,
  totalYear2,
  totalYear3,
  totalYear4,
  totalYear5
) => {
  switch (Number(year)) {
    case 1:
      return totalYear1;
    case 2:
      return totalYear2;
    case 3:
      return totalYear3;
    case 4:
      return totalYear4;
    case 5:
      return totalYear5;
    default:
      return 0;
  }
};

export {
  getYearData,
  getExtraData,
  getTotalData,
  getYearPercent,
  getYearTotal,
};
