import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { usePaymentContext } from "../context/PaymentContext";
import { isNumber } from "chart.js/helpers";
import {
  addProposalPicRequest,
  addProposalRequest,
} from "../redux/actions/proposalActions";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import ProposalCards from "./ProposalCards";
// import config from "../config";

const PaymentCard = () => {
  const { paymentData, updatePaymentData } = usePaymentContext();
  const [visibleRows, setVisibleRows] = useState(2);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [recordSaved, setRecordSaved] = useState(false);

  // const token = useSelector((state) => state.admin.data.accessToken);
  // const { data, loading } = useSelector((state) => state.proposal);

  // useEffect(() => {
  //   console.log(paymentData);
  // }, [paymentData]);

  useEffect(() => {
    if (!initialized) {
      updatePaymentData({
        corpNo: "",
        siteName: "",
        address: "",
        city: "",
        province: "",
        postalCode: "",
        phone: "",
        email: "",
        date_Created: null,

        supervisorTitle: "",
        supervisorProfit: "",
        supervisorPayrate: "",
        supervisorHours: "",
        supervisorStatHours: "",

        conciergeTitle: "",
        conciergeProfit: "",
        conciergePayrate: "",
        conciergeHours: "",
        conciergeStatHours: "",

        patrolTitle: "",
        patrolProfit: "",
        patrolPayrate: "",
        patrolHours: "",
        patrolStatHours: "",

        extraTitle1: "",
        extraProfit1: "",
        extraPayrate1: "",
        extraHours1: "",
        extraStatHours1: "",

        extraTitle2: "",
        extraProfit2: "",
        extraPayrate2: "",
        extraHours2: "",
        extraStatHours2: "",

        unionAmt: "",
        vacationAmt: "",
        additionalAmt: "",

        year2Percent: "",
        year3Percent: "",
        year4Percent: "",
        year5Percent: "",

        overheadAmt: "",
        cppPercent: "",
        eiPercent: "",
        wsibPercent: "",
        healthTax: "",
        vacationPay: "",
        hst: 13,
      });
      setInitialized(true);
    }
  }, [updatePaymentData, initialized]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    // updatePaymentData({ [id]: value || "" });
    if (isNumber(value)) updatePaymentData({ [id]: parseFloat(value) || "" });
    else updatePaymentData({ [id]: value || "" });

    updateValidation(id, value);
  };

  const updateValidation = (id, value) => {
    // Remove the error for this field if valid
    const updatedErrors = { ...errors };

    if (id === "corpNo" && value.trim() !== "") {
      delete updatedErrors.corpNo;
    }

    if (id === "siteName" && value.trim() !== "") {
      delete updatedErrors.siteName;
    }

    if (id === "supervisorTitle" && value.trim() !== "") {
      delete updatedErrors.supervisorTitle;
    }

    if (id === "supervisorProfit") {
      if (value.trim() !== "" && !isNaN(value)) {
        delete updatedErrors.supervisorProfit;
      } else if (isNaN(value)) {
        updatedErrors.supervisorProfit = "Supervisor Profit must be a number.";
      }
    }

    if (id === "conciergeProfit") {
      if (value.trim() !== "" && !isNaN(value)) {
        delete updatedErrors.conciergeProfit;
      } else if (isNaN(value)) {
        updatedErrors.conciergeProfit = "Supervisor Profit must be a number.";
      }
    }

    setErrors(updatedErrors);
  };

  const checkValidation = (data) => {
    const validationErrors = {};

    if (!data.corpNo.value) {
      validationErrors.corpNo = "Corporate Number is required.";
    }

    if (!data.siteName.value) {
      validationErrors.siteName = "Site Name is required.";
    }
    // console.log(data.supervisorTitle.value);

    if (!data.supervisorTitle.value) {
      validationErrors.supervisorTitle = "Supervisor Title is required.";
    }
    // console.log(data.supervisorProfit.value);

    if (!data.supervisorProfit.value) {
      validationErrors.supervisorProfit = "Supervisor Profit is required.";
    }

    if (!data.conciergeProfit.value) {
      validationErrors.conciergeProfit = "Concierge Profit is required.";
    }

    // If there are errors, update the state and return early
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return true;
    }
  };

  const addMoreRows = () => {
    if (visibleRows < 5) {
      setVisibleRows(visibleRows + 1);
    }
  };

  // Function to remove a row
  const removeRow = () => {
    if (visibleRows > 1) {
      setVisibleRows(visibleRows - 1);
    }
  };

  const handleSitePic = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (file) {
      // Check file type
      const validTypes = ["image/jpeg", "image/png"];
      if (!validTypes.includes(file.type)) {
        alert("Only JPG and PNG formats are allowed.");
        return;
      }

      // Check file size (example: 1MB limit)
      const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
      if (file.size > maxSizeInBytes) {
        alert("File size exceeds 1MB.");
        return;
      }

      const formData = new FormData();
      formData.append("sitepicfile", file);
      formData.append("sitePic", file.name);

      dispatch(
        addProposalPicRequest(formData, (newFileName) => {
          console.log("New file name from server:", newFileName);
          updatePaymentData({
            ...paymentData,
            sitePic: newFileName,
          });
        })
      );

      // try {
      //   const access_token = localStorage.getItem("access_token");
      //   const settings = {
      //     method: "POST",
      //     headers: {
      //       // Accept: 'application/json',
      //       // 'Content-Type': 'multipart/form-data',
      //       Authorization: `Bearer ${access_token}`,
      //     },
      //     body: formData,
      //   };
      //   const response = await fetch(
      //     config.APIURL.proposalapi.photo,
      //     settings
      //   );
      //   const data = await response.json();
      //   console.log(data);
      // } catch (error) {
      //   console.error("Error: ", error);
      // }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const createdby_Empid = parseInt(localStorage.getItem("empid"));
    const values = e.target.form;
    // console.log(e.target.form.corpNo.value);
    const form_invalid = checkValidation(values);
    if (form_invalid) return;

    const corpNo = values.corpNo.value;
    const siteName = values.siteName.value;
    const address = values.address.value;
    const city = values.city.value;
    const province = values.province.value;
    const postalCode = values.postalCode.value;
    const phone = values.phone.value;
    const email = values.email.value;
    const supervisorTitle = values.supervisorTitle.value;
    const supervisorProfit = values.supervisorProfit?.value
      ? parseFloat(values.supervisorProfit.value)
      : 0;
    const supervisorHours = values.supervisorHours?.value
      ? parseFloat(values.supervisorHours.value)
      : 0;
    const supervisorPayrate = values.supervisorPayrate?.value
      ? parseFloat(values.supervisorPayrate.value)
      : 0;
    const supervisorStatHours = values.supervisorStatHours?.value
      ? parseFloat(values.supervisorStatHours.value)
      : 0;

    const conciergeTitle = values.conciergeTitle.value;
    const conciergeProfit = values.conciergeProfit?.value
      ? parseFloat(values.conciergeProfit.value)
      : 0;
    const conciergeHours = values.conciergeHours?.value
      ? parseFloat(values.conciergeHours.value)
      : 0;
    const conciergePayrate = values.conciergePayrate?.value
      ? parseFloat(values.conciergePayrate.value)
      : 0;
    const conciergeStatHours = values.conciergeStatHours?.value
      ? parseFloat(values.conciergeStatHours.value)
      : 0;

    const patrolTitle = values.patrolTitle?.value
      ? values.patrolTitle.value
      : "";
    const patrolProfit = values.patrolProfit?.value
      ? parseFloat(values.patrolProfit.value)
      : 0;
    const patrolHours = values.patrolHours?.value
      ? parseFloat(values.patrolHours.value)
      : 0;
    const patrolPayrate = values.patrolPayrate?.value
      ? parseFloat(values.patrolPayrate.value)
      : 0;
    const patrolStatHours = values.patrolStatHours?.value
      ? parseFloat(values.patrolStatHours.value)
      : 0;

    const extraTitle1 = values.extraTitle1?.value
      ? values.extraTitle1.value
      : "";
    const extraProfit1 = values.extraProfit1?.value
      ? parseFloat(values.extraProfit1.value)
      : 0;
    const extraHours1 = values.extraHours1?.value
      ? parseFloat(values.extraHours1.value)
      : 0;
    const extraPayrate1 = values.extraPayrate1?.value
      ? parseFloat(values.extraPayrate1.value)
      : 0;
    const extraStatHours1 = values.extraStatHours1?.value
      ? parseFloat(values.extraStatHours1.value)
      : 0;

    const extraTitle2 = values.extraTitle2?.value
      ? values.extraTitle2.value
      : "";
    const extraProfit2 = values.extraProfit2?.value
      ? parseFloat(values.extraProfit2.value)
      : 0;
    const extraHours2 = values.extraHours2?.value
      ? parseFloat(values.extraHours2.value)
      : 0;
    const extraPayrate2 = values.extraPayrate2?.value
      ? parseFloat(values.extraPayrate2.value)
      : 0;
    const extraStatHours2 = values.extraStatHours2?.value
      ? parseFloat(values.extraStatHours2.value)
      : 0;

    const unionAmt = values.unionAmt?.value
      ? parseFloat(values.unionAmt.value)
      : 0;
    const vacationAmt = values.vacationAmt?.value
      ? parseFloat(values.vacationAmt.value)
      : 0;
    const additionalAmt = values.additionalAmt?.value
      ? parseFloat(values.additionalAmt.value)
      : 0;

    const year2Percent = values.year2Percent?.value
      ? parseFloat(values.year2Percent.value)
      : 0;
    const year3Percent = values.year3Percent?.value
      ? parseFloat(values.year3Percent.value)
      : 0;
    const year4Percent = values.year4Percent?.value
      ? parseFloat(values.year4Percent.value)
      : 0;
    const year5Percent = values.year5Percent?.value
      ? parseFloat(values.year5Percent.value)
      : 0;

    const overheadAmt = values.overheadAmt?.value
      ? parseFloat(values.overheadAmt.value)
      : 0;
    const cppPercent = values.cppPercent?.value
      ? parseFloat(values.cppPercent.value)
      : 0;
    const eiPercent = values.eiPercent?.value
      ? parseFloat(values.eiPercent.value)
      : 0;
    const wsibPercent = values.wsibPercent?.value
      ? parseFloat(values.wsibPercent.value)
      : 0;
    const healthTax = values.healthTax?.value
      ? parseFloat(values.healthTax.value)
      : 0;
    const vacationPay = values.vacationPay?.value
      ? parseFloat(values.vacationPay.value)
      : 0;

    const proposal_references = selectedItems.map((item) => ({
      propmanagerid: item,
    }));

    let proposalobj = {
      corpNo,
      siteName,
      address,
      city,
      province,
      postalCode,
      phone,
      email,
      sitePic: paymentData.sitePic,

      supervisorTitle,
      supervisorProfit,
      supervisorHours,
      supervisorPayrate,
      supervisorStatHours,

      conciergeTitle,
      conciergeProfit,
      conciergeHours,
      conciergePayrate,
      conciergeStatHours,

      patrolTitle,
      patrolProfit,
      patrolHours,
      patrolPayrate,
      patrolStatHours,

      extraTitle1,
      extraProfit1,
      extraHours1,
      extraPayrate1,
      extraStatHours1,

      extraTitle2,
      extraProfit2,
      extraHours2,
      extraPayrate2,
      extraStatHours2,

      unionAmt,
      vacationAmt,
      additionalAmt,

      year2Percent,
      year3Percent,
      year4Percent,
      year5Percent,

      overheadAmt,
      cppPercent,
      eiPercent,
      wsibPercent,
      healthTax,
      vacationPay,

      createdby_Empid,
      References: proposal_references,
    };

    // console.log(proposalobj);
    // dispatch(addProposalRequest(proposalobj, navigate));

    dispatch(
      addProposalRequest(proposalobj, navigate, (data) => {
        console.log("Saved: ", data);
        
        setRecordSaved(true);
      })
    );

  };

  // const handleSubmitFormdata = async (e) => {
  //   const formData = new FormData();
  //   for (const [key, value] of Object.entries(proposalobj)) {
  //     if (key === "References") {
  //       formData.append("References", JSON.stringify(proposal_references));
  //       // proposal_references.forEach((reference, index) => {
  //       //   formData.append(`References[${index}]`, JSON.stringify(reference));
  //       // });
  //     } else {
  //       formData.append(key, value);
  //     }
  //   }

  //   if (paymentData.sitepicfile) {
  //     formData.append("sitePic", paymentData.sitepicfile); // file input
  //   }

  //   console.log(formData);
  //   dispatch(addProposalRequest(formData, navigate));

  //   // try {
  //   //   const access_token = localStorage.getItem("access_token");
  //   //     const settings = {
  //   //       method: "POST",
  //   //       headers: {
  //   //         // Accept: 'application/json',
  //   //         // 'Content-Type': 'multipart/form-data',
  //   //         Authorization: `Bearer ${access_token}`,
  //   //       },
  //   //       body: formData,
  //   //     };
  //   //     const response = await fetch(
  //   //       "http://localhost:60200/api/proposalapi/createpic",
  //   //       settings
  //   //     ); // Replace with actual API URL
  //   //     const data = await response.json();
  //   //     console.log(data);
  //   //   } catch (error) {
  //   //     console.error("Error fetching categories:", error);
  //   //   }

  //   setRecordSaved(true);
  // };

  return (
    <div className="flex justify-center items-center">
      <form
        // onSubmit={handleSubmit}
        className="bg-white shadow-lg rounded-lg p-6 w-full"
      >
        <h2 className="text-2xl font-semibold text-center mb-6">
          Plan A Proposal
        </h2>
        <div className="flex flex-wrap">
          <div className="w-full p-0.5 bg-purple-200">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-0.5 mb-0.5">
              {/* Corporation No */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="corpNo"
                    className="text-sm font-semibold mb-2"
                  >
                    Corporation No
                  </label>
                  <input
                    type="text"
                    id="corpNo"
                    className={`px-3 py-2 border ${
                      errors.corpNo ? "border-red-500" : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    placeholder="Enter Corporation No."
                    value={paymentData.corpNo}
                    onChange={handleChange}
                  />
                  {errors.corpNo && (
                    <p className="text-red-500 text-sm">{errors.corpNo}</p>
                  )}
                </div>
              </div>

              {/* Site Name */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="siteName"
                    className="text-sm font-semibold mb-2"
                  >
                    Site Name
                  </label>
                  <input
                    type="text"
                    id="siteName"
                    className={`px-3 py-2 border ${
                      errors.siteName ? "border-red-500" : "border-gray-300"
                    } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                    placeholder="Enter Site Name"
                    value={paymentData.siteName}
                    onChange={handleChange}
                  />
                  {errors.siteName && (
                    <p className="text-red-500 text-sm">{errors.siteName}</p>
                  )}
                </div>
              </div>

              {/* Address */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="address"
                    className="text-sm font-semibold mb-2"
                  >
                    Address
                  </label>
                  <input
                    type="text"
                    id="address"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Address"
                    value={paymentData.address}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* City */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label htmlFor="city" className="text-sm font-semibold mb-2">
                    City
                  </label>
                  <input
                    type="text"
                    id="city"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter City"
                    value={paymentData.city}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-0.5 mb-0.5">
              {/* Province */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="province"
                    className="text-sm font-semibold mb-2"
                  >
                    Province
                  </label>
                  <input
                    type="text"
                    id="province"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Province"
                    value={paymentData.province}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* Postal Code */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="postalCode"
                    className="text-sm font-semibold mb-2"
                  >
                    Postal Code
                  </label>
                  <input
                    type="text"
                    id="postalCode"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Postal Code"
                    value={paymentData.postalCode}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Phone */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label htmlFor="phone" className="text-sm font-semibold mb-2">
                    Phone
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Phone"
                    value={paymentData.phone}
                    onChange={handleChange}
                  />
                </div>
              </div>

              {/* Email */}
              <div className="bg-purple-100 p-2">
                <div className="flex flex-col">
                  <label htmlFor="email" className="text-sm font-semibold mb-2">
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Email"
                    value={paymentData.email}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex flex-wrap">
          <div className="w-full sm:w-7/10 lg:w-7/10 bg-gray-200 p-4">
            1st Column (70%)
          </div>
          <div className="w-full sm:w-3/20 lg:w-3/20 bg-gray-300 p-4">
            2nd Column (15%)
          </div>
          <div className="w-full sm:w-3/20 lg:w-3/20 bg-gray-400 p-4">
            3rd Column (15%)
          </div>
        </div> */}
        <div className="flex flex-wrap">
          <div className="w-full md:w-7/10 p-0.5 bg-blue-200">
            <div className="hidden sm:grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-2 text-lg font-semibold">
                <button
                  type="button"
                  onClick={addMoreRows}
                  disabled={visibleRows >= 5}
                >
                  <PlusCircleIcon
                    className={`h-8 w-8 ${
                      visibleRows >= 5 ? "text-gray-400" : "text-blue-500"
                    }`}
                    title="Add Rows"
                  />
                </button>
                <button
                  type="button"
                  onClick={removeRow}
                  disabled={visibleRows === 2}
                >
                  <MinusCircleIcon
                    className={`h-8 w-8 ${
                      visibleRows === 2 ? "text-gray-400" : "text-blue-500"
                    }`}
                    title="Delete Rows"
                  />
                </button>
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="profitPer"
                  className="block text-sm font-semibold text-gray-700 mb-2"
                >
                  Profit (%)
                </label>
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="hoursPerWeek"
                  className="block text-sm font-semibold text-gray-700 mb-2"
                >
                  No of Hours per Week
                </label>
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="payRate"
                  className="block text-sm font-semibold text-gray-700 mb-2"
                >
                  Pay Rate ($/hour)
                </label>
              </div>

              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="statHours"
                  className="block text-sm font-semibold text-gray-700 mb-2"
                >
                  Statutory Holidays
                </label>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-3 text-sm font-semibold">
                <input
                  type="text"
                  id="supervisorTitle"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Title"
                  value={paymentData.supervisorTitle}
                  onChange={handleChange}
                />
                {errors.supervisorTitle && (
                  <p className="text-red-500 text-sm">
                    {errors.supervisorTitle}
                  </p>
                )}
              </div>
              <div className="bg-sky-100 p-3">
                <input
                  type="number"
                  id="supervisorProfit"
                  className={`w-full px-3 py-2 border ${
                    errors.supervisorProfit
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder={`${paymentData.supervisorTitle} Profit`}
                  min="0"
                  step="0.01"
                  value={paymentData.supervisorProfit}
                  onChange={handleChange}
                />
                {errors.supervisorProfit && (
                  <p className="text-red-500 text-sm">
                    {errors.supervisorProfit}
                  </p>
                )}
              </div>
              <div className="bg-sky-100 p-3">
                <input
                  type="number"
                  id="supervisorHours"
                  // className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  className={`w-full px-3 py-2 border ${
                    errors.supervisorHours
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder={`${paymentData.supervisorTitle} Hours`}
                  min="0"
                  step="1"
                  value={paymentData.supervisorHours}
                  onChange={handleChange}
                />
                {errors.supervisorHours && (
                  <p className="text-red-500 text-sm">
                    {errors.supervisorHours}
                  </p>
                )}
              </div>
              <div className="bg-sky-100 p-3">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    id="supervisorPayrate"
                    className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.supervisorTitle} Payrate`}
                    min="0"
                    step="0.01"
                    value={paymentData.supervisorPayrate}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-sky-100 p-3">
                <input
                  type="number"
                  id="supervisorStatHours"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={`${paymentData.supervisorTitle} Statutory Holidays`}
                  min="0"
                  step="1"
                  value={paymentData.supervisorStatHours}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-3 text-sm font-semibold">
                <input
                  type="text"
                  id="conciergeTitle"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Title"
                  value={paymentData.conciergeTitle}
                  onChange={handleChange}
                />
              </div>
              <div className="bg-sky-100 p-3">
                <input
                  type="number"
                  id="conciergeProfit"
                  className={`w-full px-3 py-2 border ${
                    errors.conciergeProfit
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  placeholder={`${paymentData.conciergeTitle} Profit`}
                  min="0"
                  step="0.01"
                  value={paymentData.conciergeProfit}
                  onChange={handleChange}
                />
                {errors.conciergeProfit && (
                  <p className="text-red-500 text-sm">
                    {errors.conciergeProfit}
                  </p>
                )}
              </div>
              <div className="bg-sky-100 p-3">
                <input
                  type="number"
                  id="conciergeHours"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={`${paymentData.conciergeTitle} Hours`}
                  min="0"
                  step="1"
                  value={paymentData.conciergeHours}
                  onChange={handleChange}
                />
              </div>
              <div className="bg-sky-100 p-3">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    id="conciergePayrate"
                    className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.conciergeTitle} Payrate`}
                    min="0"
                    step="0.01"
                    value={paymentData.conciergePayrate}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-sky-100 p-3">
                <input
                  type="number"
                  id="conciergeStatHours"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder={`${paymentData.conciergeTitle} Statutory Holidays`}
                  min="0"
                  step="1"
                  value={paymentData.conciergeStatHours}
                  onChange={handleChange}
                />
              </div>
            </div>
            {visibleRows > 2 && (
              <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
                <div className="bg-sky-100 p-3 text-sm font-semibold">
                  <input
                    type="text"
                    id="patrolTitle"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Title"
                    value={paymentData.patrolTitle}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="patrolProfit"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.patrolTitle} Profit`}
                    min="0"
                    step="0.01"
                    value={paymentData.patrolProfit}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="patrolHours"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.patrolTitle} Hours`}
                    min="0"
                    step="1"
                    value={paymentData.patrolHours}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      id="patrolPayrate"
                      className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={`${paymentData.patrolTitle} Payrate`}
                      min="0"
                      step="0.01"
                      value={paymentData.patrolPayrate}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="patrolStatHours"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.patrolTitle} Statutory Holidays`}
                    min="0"
                    step="1"
                    value={paymentData.patrolStatHours}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {visibleRows > 3 && (
              <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
                <div className="bg-sky-100 p-3 text-sm font-semibold">
                  <input
                    type="text"
                    id="extraTitle1"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Title"
                    value={paymentData.extraTitle1}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="extraProfit1"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.extraTitle1} Profit`}
                    min="0"
                    step="0.01"
                    value={paymentData.extraProfit1}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="extraHours1"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.extraTitle1} Hours`}
                    min="0"
                    step="1"
                    value={paymentData.extraHours1}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      id="extraPayrate1"
                      className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={`${paymentData.extraTitle1} Payrate`}
                      min="0"
                      step="0.01"
                      value={paymentData.extraPayrate1}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="extraStatHours1"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.extraTitle1} Statutory Holidays`}
                    min="0"
                    step="1"
                    value={paymentData.extraStatHours1}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}
            {visibleRows > 4 && (
              <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
                <div className="bg-sky-100 p-3 text-sm font-semibold">
                  <input
                    type="text"
                    id="extraTitle2"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Title"
                    value={paymentData.extraTitle2}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="extraProfit2"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.extraTitle2} Profit`}
                    min="0"
                    step="0.01"
                    value={paymentData.extraProfit2}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="extraHours2"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.extraTitle2} Hours`}
                    min="0"
                    step="1"
                    value={paymentData.extraHours2}
                    onChange={handleChange}
                  />
                </div>
                <div className="bg-sky-100 p-3">
                  <div className="relative">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                      $
                    </span>
                    <input
                      type="number"
                      id="extraPayrate2"
                      className="w-full pl-8 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      placeholder={`${paymentData.extraTitle2} Payrate`}
                      min="0"
                      step="0.01"
                      value={paymentData.extraPayrate2}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="bg-sky-100 p-3">
                  <input
                    type="number"
                    id="extraStatHours2"
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder={`${paymentData.extraTitle2} Statutory Holidays`}
                    min="0"
                    step="1"
                    value={paymentData.extraStatHours2}
                    onChange={handleChange}
                  />
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-2 text-sm font-semibold">
                Add $$$ to Charge Client
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="unionAmt"
                  className="bg-sky-100 p-3 text-sm font-semibold w-1/3"
                >
                  Union
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    id="unionAmt"
                    className="w-full pl-8 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Union"
                    min="0"
                    step="0.01"
                    value={paymentData.unionAmt}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="vacationAmt"
                  className="bg-sky-100 p-3 text-sm font-semibold w-1/3"
                >
                  Vacation
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    id="vacationAmt"
                    className="w-full pl-8 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Vacation"
                    min="0"
                    step="0.01"
                    value={paymentData.vacationAmt}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="additionalAmt"
                  className="bg-sky-100 p-3 text-sm font-semibold w-1/3"
                >
                  Additional
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                    $
                  </span>
                  <input
                    type="number"
                    id="additionalAmt"
                    className="w-full pl-8 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Additional"
                    min="0"
                    step="0.01"
                    value={paymentData.additionalAmt}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-sky-100 p-2"></div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-0.5 mb-0.5">
              <div className="bg-sky-100 p-2 text-sm font-semibold">
                Yearly Increase (%)
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="year2Percent"
                  className="bg-sky-100 p-2 text-sm font-semibold w-1/3"
                >
                  Year 2 Increase (%)
                </label>
                <input
                  type="number"
                  id="year2Percent"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter Year 2 Increase"
                  min="0"
                  step="0.01"
                  value={paymentData.year2Percent}
                  onChange={handleChange}
                />
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="year3Percent"
                  className="bg-sky-100 p-3 text-sm font-semibold w-1/3"
                >
                  Year 3 Increase (%)
                </label>
                <input
                  type="number"
                  id="year3Percent"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter Year 3 Increase"
                  min="0"
                  step="0.01"
                  value={paymentData.year3Percent}
                  onChange={handleChange}
                />
              </div>
              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="year4Percent"
                  className="bg-sky-100 p-3 text-sm font-semibold w-1/3"
                >
                  Year 4 Increase (%)
                </label>
                <input
                  type="number"
                  id="year4Percent"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter Year 4 Increase"
                  min="0"
                  step="0.01"
                  value={paymentData.year4Percent}
                  onChange={handleChange}
                />
              </div>

              <div className="bg-sky-100 p-2">
                <label
                  htmlFor="year5Percent"
                  className="bg-sky-100 p-3 text-sm font-semibold w-1/3"
                >
                  Year 5 Increase (%)
                </label>
                <input
                  type="number"
                  id="year5Percent"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter Year 5 Increase"
                  min="0"
                  step="0.01"
                  value={paymentData.year5Percent}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="w-full md:w-3/10 p-1 bg-indigo-200">
            <div className="space-y-1">
              <div className="bg-indigo-100 p-2">
                <div className="grid grid-cols-3 gap-2 items-center">
                  <label
                    htmlFor="overheadAmt"
                    className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                  >
                    Overhead ($)
                  </label>
                  <input
                    type="number"
                    id="overheadAmt"
                    className="col-span-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Overhead"
                    min="0"
                    step="0.01"
                    value={paymentData.overheadAmt}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-indigo-100 p-2">
                <div className="grid grid-cols-3 gap-2 items-center">
                  <label
                    htmlFor="cppPercent"
                    className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                  >
                    Employer CPP (%)
                  </label>
                  <input
                    type="number"
                    id="cppPercent"
                    className="col-span-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Employer CPP"
                    min="0"
                    step="0.001"
                    value={paymentData.cppPercent}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-indigo-100 p-2">
                <div className="grid grid-cols-3 gap-2 items-center">
                  <label
                    htmlFor="eiPercent"
                    className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                  >
                    Employer EI (%)
                  </label>
                  <input
                    type="number"
                    id="eiPercent"
                    className="col-span-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Employer EI"
                    min="0"
                    step="0.0001"
                    value={paymentData.eiPercent}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-indigo-100 p-2">
                <div className="grid grid-cols-3 gap-2 items-center">
                  <label
                    htmlFor="wsibPercent"
                    className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                  >
                    WSIB (%)
                  </label>
                  <input
                    type="number"
                    id="wsibPercent"
                    className="col-span-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter WSIB"
                    min="0"
                    step="0.01"
                    value={paymentData.wsibPercent}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="bg-indigo-100 p-2">
                <div className="grid grid-cols-3 gap-2 items-center">
                  <label
                    htmlFor="healthTax"
                    className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                  >
                    Health Tax (%)
                  </label>
                  <input
                    type="number"
                    id="healthTax"
                    className="col-span-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Health Tax"
                    min="0"
                    step="0.0001"
                    value={paymentData.healthTax}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="bg-indigo-100 p-2">
                <div className="grid grid-cols-3 gap-2 items-center">
                  <label
                    htmlFor="vacationPay"
                    className="p-2 text-sm font-semibold bg-indigo-100 col-span-1"
                  >
                    Vacation Pay (%)
                  </label>
                  <input
                    type="number"
                    id="vacationPay"
                    className="col-span-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter Vacation Pay"
                    min="0"
                    step="0.0001"
                    value={paymentData.vacationPay}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-7/10 p-1 bg-orange-100">
            <div className="space-y-1">
              <div className="bg-orange-50 p-3">
                <MultiSelectDropdown
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/10 p-1 bg-orange-100">
            <div className="space-y-1">
              <div className="bg-orange-50 p-2">
                <div className="flex flex-col">
                  <label
                    htmlFor="sitepic"
                    className="text-sm font-semibold mb-2"
                  >
                    Upload Photo
                  </label>
                  <input
                    type="file"
                    id="sitePic"
                    className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Select Photo"
                    onChange={handleSitePic}
                    accept="image/*"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <ProposalCards recordSaved={recordSaved} />
          </div>
          <div className="w-full text-center">
            <div className="p-3">
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 mx-auto block"
              >
                Save Proposal
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PaymentCard;
