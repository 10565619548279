import React from "react";
import { usePaymentContext } from "../context/PaymentContext";
import { getYearData } from "../utility/functions";
import { formatPercent } from "../utility/utility";

const YearCard = ({ year, emptype }) => {
  const { paymentData, contextData } = usePaymentContext();

  const yearData = getYearData(emptype, year, paymentData, contextData);
  const {
    emptitle,
    profit,
    payrate,
    billingRate,
    statBillingRate,
    hours,
    leapHours,
    statHours,
    billingYear,
    billingLeap,
    billingStat,
    billingTotal,
  } = yearData;

  return (
    <div className="container mx-auto h-full">
      <div className="overflow-x-auto">
        <div className="m-2 uppercase tracking-wide text-sm text-indigo-500 font-semibold">
          Year {year}
        </div>
        <p className="m-2 text-gray-800">@ {formatPercent(profit)}</p>

        <table className="min-w-full bg-white">
          <thead>
            <tr className="bg-blue-50">
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                {emptitle} @
              </th>
              <th className="w-3/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Billing Rate
              </th>
              <th className="w-1/5 py-2 px-2 border border-gray-200 text-center text-xs font-medium text-gray-800 uppercase tracking-wider">
                Statutory Holiday Billing Rates
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {payrate}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {billingRate}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-center">
                {statBillingRate}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-medium text-center">
                {hours}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Hours per Week x 52 Weeks x {billingRate} =
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-medium text-right">
                {billingYear}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-medium text-center">
                {leapHours!==0 && leapHours}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Hours per Extra Calendar Day x {billingRate} =
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-medium text-right">
                {billingLeap}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200 text-sm font-medium text-center">
                {statHours}
              </td>
              <td className="py-2 px-2 border border-gray-200 text-xs font-medium">
                Hours per Day x 9 Statutory Holidays x {statBillingRate} =
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-medium text-right">
                {billingStat}
              </td>
            </tr>
            <tr>
              <td className="py-2 px-2 border border-gray-200"></td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                Total
              </td>
              <td className="py-2 px-2 border border-gray-200 text-sm font-semibold text-right">
                {billingTotal}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default YearCard;
